import { Tooltip } from "antd";
import _ from "lodash";
import moment from "moment";
import { LabelHTMLAttributes, PureComponent, ReactNode } from "react";
import ReactDOM from "react-dom";
import VMasker from "vanilla-masker";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import withDoubleClickEdit from "../../../shared/hoc/with-double-click-edit";
import { KMainFunctions } from "../../../shared/utilty/main-functions";
import { CommonProps } from "../common-props";
import { DashboardState } from "../kuika-cl-model-runtimes";

declare let window: any;

interface LabelProps extends LabelHTMLAttributes<any> {
  value?: string;
  defaultText?: string;
  style?: React.CSSProperties;
  formatter?:
    | "money"
    | "money-var-1"
    | "phone"
    | "percent"
    | "fractional-2"
    | "fractional-5"
    | "date"
    | "time"
    | "datetime"
    | "date-var-1"
    | "time-ago"
    | "short-date"
    | "round"
    | "money-tl"
    | "custom date";
  maxLine: number;
  writingMode?: "horizontal" | "vertical";
  tooltip?: string;
  customFormatter?: string;
}

interface LabelState {}

class Label extends PureComponent<LabelProps & CommonProps, LabelState> {
  constructor(props: LabelProps) {
    super(props);
    this.state = {};
  }

  componentDidMount(): void {
    this.updateStyle();
  }

  updateStyle = () => {
    const node: any = ReactDOM.findDOMNode(this);
    let style: React.CSSProperties = _.clone(this.props.style);
    if (
      this.props?.style?.alignItems !== "flex-start" &&
      node &&
      node.children &&
      node.children.length > 0 &&
      window.kuika.dashboardState !== DashboardState.reportDesigner
    ) {
      let isInline = false;
      if (this.props?.style?.display === "inline") {
        isInline = true;
      }
      KMainFunctions.handleAlignments(node, style, isInline);
    }
  };

  getFormattedValue() {
    let { formatter, value, defaultText } = this.props;
    if (
      defaultText != undefined &&
      defaultText != "" &&
      defaultText != null &&
      (value === "" || value === undefined || value === null)
    ) {
      return defaultText;
    }
    if (formatter && value && value?.toString()?.trim() !== "") {
      switch (formatter) {
        case "money":
          if (Number.isNaN(Number(value))) {
            return "0.00";
          }

          return new Intl.NumberFormat("tr-TR", {
            style: "currency",
            currency: "TRY",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
            .format(Number(value))
            .replace("₺", "");

        case "money-var-1":
          if (Number.isNaN(Number(value))) {
            return "0.00";
          }

          return new Intl.NumberFormat("tr-TR", {
            style: "currency",
            currency: "TRY",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          })
            .format(Number(value))
            .replace("₺", "");

        case "money-tl":
          if (Number.isNaN(Number(value))) {
            return "₺0.00";
          }

          return new Intl.NumberFormat("tr-TR", {
            style: "currency",
            currency: "TRY",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }).format(Number(value));

        case "phone":
          return VMasker.toPattern(this.props.value, "(999) 999-9999");

        case "percent":
          return `${this.props.value}%`;

        case "fractional-2":
          const number2 = Number(value);
          return isNaN(number2) ? value : number2.toFixed(2);

        case "fractional-5":
          const number5 = Number(value);
          return isNaN(number5) ? value : number5.toFixed(5);

        case "datetime":
          const datetimeString = this.parseDateString(value, {
            formatStr: "DD/MM/YYYY HH:mm:ss"
          });
          return datetimeString;

        case "date":
          const dateString = this.parseDateString(value, {
            formatStr: "DD/MM/YYYY"
          });
          return dateString;

        case "date-var-1":
          const dateVar1String = this.parseDateString(value, {
            formatStr: "DD:MM:YYYY"
          });
          return dateVar1String;

        case "time":
          const timeString = this.parseDateString(value, {
            formatStr: "HH:mm:ss"
          });
          return timeString;

        case "short-date":
          const shortDateString = this.parseDateString(value, {
            formatStr: "MMM Do"
          });
          return shortDateString;

        case "time-ago":
          const timeAgoString = this.parseDateString(value, {
            formatStr: "DD/MM/YYYY HH:mm:ss",
            timeAgo: true
          });
          return timeAgoString;

        case "custom date":
          if (this.props.customFormatter && this.props.customFormatter !== "") {
            const customFormattedDate = this.parseDateString(value, {
              formatStr: this.props.customFormatter
            });
            return customFormattedDate;
          }
          return value;
          break;

        default:
          return value;
      }
    } else {
      if (value && typeof value === "string") {
        const kuikaCulture = localStorage.getItem("ml");
        const isLangTR = kuikaCulture?.toLowerCase() === "tr_tr";

        if (this.props.style?.textTransform === "lowercase" && (value.includes("İ") || value.includes("I"))) {
          if (isLangTR) {
            value = value.replace(/I/g, "ı");
          }
          value = value.replace(/İ/g, "i");
        } else if (this.props.style?.textTransform === "uppercase" && (value.includes("i") || value.includes("ı"))) {
          if (isLangTR) {
            value = value.replace(/i/g, "İ");
          }
          value = value.replace(/ı/g, "I");
        } else if (this.props?.style.textTransform === "capitalize") {
          if (isLangTR && value[0] === "i") {
            value = value.replace("i", "İ");
          } else if (value[0] === "ı") {
            value = value.replace("ı", "I");
          }
        }
      }

      return value?.toString();
    }
  }

  parseDateString = (value: any, arg1: { formatStr: string; timeAgo?: boolean }) => {
    try {
      const date = moment(new Date(value));

      if (!date.isValid()) {
        return value;
      }

      let result: any = date.format(arg1.formatStr);

      if (arg1.timeAgo) {
        result = date.fromNow();
      }

      if (result.indexOf("Invalid") > -1) {
        result = value;
      }
      return result;
    } catch (ex: any) {
      return value;
    }
  };

  getStyles = (): { containerStyles: React.CSSProperties; labelStyles: React.CSSProperties } => {
    let containerStyles: React.CSSProperties = {};
    let labelStyles: React.CSSProperties = {};

    if (this.props.style) {
      const {
        // LAYOUT STYLES
        marginTop,
        marginRight,
        marginLeft,
        marginBottom,
        paddingTop,
        paddingRight,
        paddingLeft,
        paddingBottom,
        width,
        height,
        minWidth,
        minHeight,
        maxWidth,
        maxHeight,
        textAlign,
        alignItems,
        display,
        // FILL STYLES
        backgroundColor,
        // BORDER STYLES
        borderColor,
        borderTopWidth,
        borderRightWidth,
        borderBottomWidth,
        borderLeftWidth,
        borderTopLeftRadius,
        borderTopRightRadius,
        borderBottomRightRadius,
        borderBottomLeftRadius,
        borderStyle,
        // OTHERS
        cursor,
        // LABEL STYLES
        ...restOfStyles
      } = this.props.style;

      labelStyles = restOfStyles;

      containerStyles = {
        marginTop,
        marginRight,
        marginLeft,
        marginBottom,
        paddingTop,
        paddingRight,
        paddingLeft,
        paddingBottom,
        width,
        height,
        minWidth,
        minHeight,
        maxWidth,
        maxHeight,
        textAlign,
        alignItems,
        display,
        backgroundColor,
        borderColor,
        borderTopWidth,
        borderRightWidth,
        borderBottomWidth,
        borderLeftWidth,
        borderTopLeftRadius,
        borderTopRightRadius,
        borderBottomRightRadius,
        borderBottomLeftRadius,
        borderStyle,
        cursor
      };
    }

    containerStyles.cursor = "inherit";
    labelStyles.cursor = "inherit";
    if (this.props.showCursorPointer) {
      containerStyles.cursor = "pointer";
      labelStyles.cursor = "pointer";
    }

    if (containerStyles.display !== "block") {
      containerStyles.display = "inline-block";
    }

    if (this.props.maxLine && this.props.maxLine > 0) {
      containerStyles.overflow = "hidden";
      // labelStyles.overflow = "hidden";
      labelStyles.display = "-webkit-box";
      labelStyles.WebkitLineClamp = this.props.maxLine;
      labelStyles.lineClamp = this.props.maxLine;
      labelStyles.WebkitBoxOrient = "vertical";
    }

    if (window.kuika?.isDesignTime && window.kuika.dashboardState === DashboardState.reportDesigner) {
      containerStyles.width = "auto";
      containerStyles.height = "auto";
      labelStyles.width = "auto";
      labelStyles.height = "auto";
      containerStyles.display = "inline-block";
      containerStyles.lineHeight = "unset";
    }

    if (this.props.writingMode === "vertical") {
      labelStyles.writingMode = "vertical-rl";
      labelStyles.textOrientation = "sideways";
    }

    if (!this.props.writingMode || this.props.writingMode === "horizontal") {
      labelStyles.writingMode = "horizontal-tb";
      labelStyles.textOrientation = "mixed";
    }

    return { containerStyles, labelStyles };
  };

  getNumbersOfStyleProperties = (val: any) => {
    let numb: any = val?.match(/\d/g);
    numb = numb?.join("");
    return parseInt(numb);
  };

  getProps = () => {
    const props = { ...this.props };
    if (props.style) {
      delete props.style;
    }

    return props;
  };

  getLangProp = () => {
    const textTransformStyle = this.props.style?.textTransform;
    if (
      textTransformStyle === "lowercase" ||
      textTransformStyle === "uppercase" ||
      textTransformStyle === "capitalize"
    ) {
      const kuikaCulture = localStorage.getItem("ml");
      if (!kuikaCulture) return undefined;

      return kuikaCulture.replace("_", "-");
    }

    return undefined;
  };

  renderLabel = () => {
    const { containerStyles, labelStyles } = this.getStyles();
    return (
      <div style={containerStyles}>
        <label {...this.getProps()} style={labelStyles} lang={this.getLangProp()}>
          {this.getFormattedValue()}
        </label>
      </div>
    );
  };

  render(): ReactNode {
    if (this.props.tooltip) {
      return (
        <>
          <Tooltip color={"white"} title={this.props.tooltip}>
            {this.renderLabel()}
          </Tooltip>
        </>
      );
    }

    return this.renderLabel();
  }
}

const label = withDoubleClickEdit(withCommonEvents(Label), "value");
export { label as Label };
