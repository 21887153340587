import { DownloadOutlined, MenuOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Empty, FormInstance, Input, Popover, Row, Table } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { ColumnType } from "antd/lib/table";
import xlsx from "better-xlsx";
import { saveAs } from "file-saver";
import { Guid } from "guid-typescript";
import _ from "lodash";
import moment from "moment";
import objectPath from "object-path";
import React, { Component, ReactNode } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ReactDOM from "react-dom";
import isEqual from "react-fast-compare";
import shallowequal from "shallowequal";
import VMasker from "vanilla-masker";
import CarouselPlaceholder from "../../../../public/images/carousel-placeholder.svg";
import EmptyDataSvg from "../../../../public/images/empty-table.svg";
import FlexGridPlaceholder from "../../../../public/images/flex-grid-placeholder.svg";
import HorizontalGridPlaceholder from "../../../../public/images/horizontal-grid-placeholder.svg";
import { KMainFunctions } from "../../../shared/utilty/main-functions";
import { CommonProps } from "../common/common-props";
import { Image } from "../image/image";
import { DashboardState, toCamelCase } from "../kuika-cl-model-runtimes";
import { CarouselBodyCell } from "./body/carousel/carousel-body-cell";
import { CarouselBodyWrapper, CarouselBodyWrapperProps } from "./body/carousel/carousel-body-wrapper";
import { CarouselItem } from "./body/carousel/carousel-item";
import { FlexGridBodyCell } from "./body/flex-grid/flex-grid-body-cell";
import { FlexGridBodyWrapper } from "./body/flex-grid/flex-grid-body-wrapper";
import { FlexGridItem } from "./body/flex-grid/flex-grid-item";
import { GalleryBodyCell } from "./body/gallery/gallery-body-cell";
import { GalleryBodyWrapper, GalleryBodyWrapperProps } from "./body/gallery/gallery-body-wrapper";
import { GalleryItem, GalleryItemProps } from "./body/gallery/gallery-item";
import { GoogleMapsBodyCell } from "./body/google-maps/google-maps-body-cell";
import { GoogleMapsBodyWrapper, GoogleMapsBodyWrapperProps } from "./body/google-maps/google-maps-body-wrapper";
import { GoogleMapsItem, ICoords, MarkerDetailPosition, MarkerDetailView } from "./body/google-maps/google-maps-item";
import { TableBodyCell } from "./body/table/table-body-cell";
import { TableBodyWrapper } from "./body/table/table-body-wrapper";
import { TableColumnProps } from "./body/table/table-column";
import CustomFilterDropdown from "./custom-table-filter-dropdown";
import { TableData, TableDataProps } from "./data/table-data";
import { TableHeaderCell } from "./header/table-header-cell";
import { TableHeaderRow } from "./header/table-header-row";
import { RefreshDelegate } from "./refresh-delegate";
import "./table.scss";
import { CarouselViewWrapper } from "./wrapper/carousel-view-wrapper";
import { FlexGridViewWrapper } from "./wrapper/flex-grid-view-wrapper";
import { GalleryViewWrapper } from "./wrapper/gallery-view-wrapper";
import { GoogleMapsViewWrapper } from "./wrapper/google-maps-view-wrapper";
import { TableViewWrapper } from "./wrapper/table-view-wrapper";

export type ViewMode = "table" | "gallery" | "carousel" | "flex-grid" | "google-maps";

declare let window: any;

export enum StringFilterTypes {
  Contains = "contains",
  DoesNotContain = "does_not_contain",
  Equals = "equals",
  DoesNotEqual = "does_not_equal",
  BeginsWith = "begins_with",
  EndsWith = "ends_with",
  Blank = "blank",
  NotBlank = "not_blank"
}

export enum NumberFilterTypes {
  Equals = "number_equals",
  DoesNotEqual = "number_does_not_equal",
  GreaterThan = "greater_than",
  LessThan = "less_than",
  GreaterThanOrEqual = "greater_than_or_equal",
  LessThanOrEqual = "less_than_or_equal",
  Between = "number_between",
  Blank = "number_blank",
  NotBlank = "number_not_blank"
}

export enum DateFilterTypes {
  Equals = "date_equals",
  DoesNotEqual = "date_does_not_equal",
  After = "after",
  Before = "before",
  Between = "date_between",
  Blank = "date_blank",
  NotBlank = "date_not_blank"
}

export enum BooleanFilterTypes {
  Equals = "boolean_equals",
  DoesNotEqual = "boolean_does_not_equal",
  Blank = "boolean_blank",
  NotBlank = "boolean_not_blank"
}

export enum ValueType {
  String = "string",
  Number = "number",
  Date = "date",
  Boolean = "boolean"
}

export enum FooterOptions {
  None = "none",
  Average = "average",
  Sum = "sum",
  Min = "min",
  Max = "max",
  Count = "count"
}

export interface ITableProps {
  children?: any;
  nodatafoundmessage?: string;
  rowBgColor?: (rowData: any, rowIndex?: number) => string;
  rowFontColor?: (rowData: any, rowIndex?: number) => string;
  dataSource?: any[];
  loading?: boolean;
  size?: SizeType;
  sorter?: boolean;
  pagination?: boolean;
  showHeader?: boolean;
  style?: any;
  mode?: ViewMode;
  striped?: boolean;
  stripedColor?: string;
  // Maps Props
  onChange?: (value?: any | undefined) => void;
  value?: any;
  latitude?: string;
  longitude?: string;
  zoom?: number; /// zoom /// 1-> World /// 5-> Landmass/continent 10 -> City /// 15-> Streets /// 20-> Buildings şeklinde tanımlanırsa veya açıklama yazılırsa low code için daha anlamlı olabilir.
  isMarkerPicker?: boolean;
  isPicker?: boolean;
  hasSearchBox?: boolean;
  disableDoubleClickZoom?: boolean;
  fullscreenControl?: boolean;
  streetViewControl?: boolean;
  scaleControl?: boolean;
  zoomControl?: boolean;
  panControl?: boolean;
  rotateControl?: boolean;
  mapTypeControl?: boolean;
  autoCenter?: boolean;
  designClicked?: boolean;
  onClickDesign?: (value: boolean) => void;
  dataLatField?: string;
  dataLngField?: string;
  markerDetailView?: MarkerDetailView;
  markerDetailPosition?: MarkerDetailPosition;
  markerDetailHeight?: number;
  markerDetailWidth?: number;
  circleRadius?: number;
  groupPickerIcon?: string;
  groupPickerColor?: string;
  selectedLocationIcon?: string;
  selectedLocationColor?: string;
  currentLocationIcon?: string;
  currentLocationColor?: string;
  // Carousel Props
  setCurrentSlideNumber?: (val: number) => void;
  startingIndex?: number;
  hideTableOnEmpty?: boolean;
  rowHoverFontColor?: string;
  transformedOnMobileResolution?: boolean;
  rowHoverBgColor?: string;
  kuikacomponentname?: string;
  onRowEditFinished?: () => void;
  form?: FormInstance<any>;
  insertRowActive?: boolean;
  insertRowPosition?: "top" | "bottom";
  noDataFoundImage?: string;
  filtering?: boolean;
  searchable?: boolean;
  tableWidthMode?: "fit" | "horizontal scroll" | "scroll";
  columnChooser?: boolean;
  editableAlwaysActive?: boolean;
  onConfigChange?: () => void;
  multiSelect?: boolean;
  selectedValues?: any[];
  onSelectedValuesChange?: () => void;
  fixedHeader?: boolean;
  pageSize?: number;
  showSizeChanger?: boolean;
  options?: any[];
  onRowDoubleClick?: () => void;
  showNoDataFound?: boolean;
  resizableColumns?: boolean;
  selectedRowColor?: string;
  editable?: boolean;
  globalSearch?: boolean;
  export?: boolean;
  datavaluefield?: string;
}

export interface ITableState {
  columnTitles?: string[];
  selectedRowKeys: any;
  selectedRowKeysString: any;
  columns?: TableColumnProps & CommonProps[];
  headerStyle?: any;
  rowStyle?: any;
  footerStyle?: any;
  headerRenderer?: any;
  rowRenderer?: any;
  selectedIndexGuid: string;
  selectedColumnIndexGuid: string;
  selectedRowDataGuid: string;
  // Maps States:
  maps?: any;
  map?: any;
  isDataMarkerSelected: boolean;
  value?: any;
  uniqueKey?: Guid;
  activeEditableRowIndex?: number;
  randomGuid?: string;
  searchText: string;
  columnSearchTexts?: { [key: string]: string };
  carouselStartingIndex?: number;
  hiddenColumnList?: any[];
  columnOrder?: any[];
  filteringOptions?: any;
  sorterOptions?: any;
  pageSize?: any;
  resizingColumnIndex?: number;
  columnWidths?: any;
}
let isEventListenerAdded = false;

export abstract class BaseTable<P, S> extends Component<ITableProps & P & CommonProps, ITableState & S> {
  private static readonly DEFAULT_PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

  private memoizedDynamicCssResult = "";

  timeOutId = undefined;

  lastSelectedLat: any;

  lastSelectedLng: any;

  resizingColumnIndexRef = React.createRef<number>();

  constructor(props: ITableProps & P) {
    super(props);
    this.state = {
      columns: [],
      selectedRowKeys: "",
      selectedRowKeysString: this.props.selectedValues || "",
      selectedIndexGuid: Guid.create().toString(),
      selectedColumnIndexGuid: Guid.create().toString(),
      selectedRowDataGuid: Guid.create().toString(),
      uniqueKey: Guid.create(),
      activeEditableRowIndex: window.kuika.isDesignTime ? 0 : -1,
      randomGuid: Guid.create().toString(),
      searchText: "",
      columnSearchTexts: {},
      carouselStartingIndex: 0,
      hiddenColumnList: [],
      columnOrder: [],
      filteringOptions: {},
      sorterOptions: {},
      pageSize: this.props.pageSize && this.props.pageSize > 1 ? this.props.pageSize : 10,
      resizingColumnIndex: null,
      columnWidths: undefined
    } as any;
  }

  public static defaultProps = {
    mode: "table",
    designClicked: true
  };

  static withColumnProps(props: any, value: any, rowData: any, rowIndex: number): any {
    props.value = value;
    props.rowData = rowData;
    props.rowIndex = rowIndex;
    return props;
  }

  static getDerivedStateFromProps(nextProps: ITableProps & CommonProps, prevState: ITableState) {
    const result: Partial<ITableState> = {
      selectedIndexGuid: prevState.selectedIndexGuid,
      selectedColumnIndexGuid: prevState.selectedColumnIndexGuid,
      selectedRowDataGuid: prevState.selectedRowDataGuid,
      maps: prevState.maps,
      map: prevState.map,
      isDataMarkerSelected: prevState.isDataMarkerSelected,
      activeEditableRowIndex: prevState.activeEditableRowIndex,
      columnSearchTexts: prevState.columnSearchTexts,
      searchText: prevState.searchText,
      hiddenColumnList: prevState.hiddenColumnList,
      filteringOptions: prevState.filteringOptions,
      sorterOptions: prevState.sorterOptions,
      selectedRowKeys: prevState.selectedRowKeys,
      selectedRowKeysString: prevState.selectedRowKeysString,
      columnWidths: prevState.columnWidths
    };
    if (nextProps.children) {
      result.columns = [] as CommonProps[];
      if (nextProps.mode === "carousel") {
        result.rowStyle = nextProps.style;
        BaseTable.setCarouselItem(result, nextProps.children);
      } else if (nextProps.mode === "gallery") {
        result.rowStyle = nextProps.style;
        BaseTable.setGalleryItem(result, nextProps.children);
      } else if (nextProps.mode === "flex-grid") {
        result.rowStyle = nextProps.style;
        BaseTable.setFlexGridItem(result, nextProps.children);
      } else if (nextProps.mode === "google-maps") {
        if (nextProps.value !== undefined) {
          result.value = nextProps.value;
        }
        React.Children.forEach(nextProps.children, (child, childIndex) => {
          switch (childIndex) {
            // GoogleMapsMarker
            case 0:
              BaseTable.setGoogleMapsItem(result, child, nextProps);
              break;
          }
        });
      } else {
        React.Children.forEach(nextProps.children, (child, childIndex) => {
          switch (childIndex) {
            // Header
            case 0:
              if (
                child.type.name == "TableHeader" ||
                (child.props.kuikacomponentname && child.props.kuikacomponentname === "TableHeader") ||
                child.type.name == "ReportTableHeader" ||
                (child.props.kuikacomponentname && child.props.kuikacomponentname === "ReportTableHeader")
              ) {
                BaseTable.setHeader(result, child, prevState, nextProps);
              } else if (
                child.type.name == "TableRow" ||
                (child.props.kuikacomponentname && child.props.kuikacomponentname === "TableRow") ||
                child.type.name == "ReportTableRow" ||
                (child.props.kuikacomponentname && child.props.kuikacomponentname === "ReportTableRow")
              ) {
                BaseTable.setRow(result, child, nextProps, nextProps.sorter, nextProps.filtering, nextProps.dataSource);
                result.headerRenderer = (_props: any) => {
                  return React.createElement("div");
                };
              }
              break;
            // Row
            default:
              BaseTable.setRow(result, child, nextProps, nextProps.sorter, nextProps.filtering, nextProps.dataSource);
              break;
          }
        });
      }

      if (prevState.columnOrder.length > 0) {
        result.columns = prevState.columnOrder
          .map((orderId) => result?.columns?.find((col) => col.id === orderId))
          .filter(Boolean);
      }
      return result;
    }
  }

  static setHeader = (result: Partial<ITableState>, child: any, prevState: any, nextProps: any) => {
    let headerStyle: any = {};

    if (child.props.style) {
      headerStyle = child.props.style;
    }

    if (child.props.writingMode && child.props.writingMode === "vertical") {
      headerStyle.writingMode = "vertical-lr";
    }

    if (
      child.props.textDirection &&
      (child.props.textDirection === "Rotate Up" || child.props.textDirection === "Rotate Down")
    ) {
      headerStyle.writingMode = "vertical-lr";
      if (child.props.textDirection === "Rotate Up") {
        headerStyle.transform = "rotate(180deg)";
      }
    }

    result.headerStyle = headerStyle;
    result.headerRenderer = (props: any) => {
      let _props = { ...props };

      _props.wrapperClassname = `.ktable_${prevState.uniqueKey.toString().substring(0, 8)}`;
      _props.transformedOnMobileResolution = nextProps.transformedOnMobileResolution;

      return React.cloneElement(child, _props);
    };

    if (child.props.columnTitles) {
      result.columnTitles = child.props.columnTitles.split(";");
    }
  };

  static setRow = (
    result: Partial<ITableState>,
    child: any,
    _nextProps: ITableProps,
    sorter?: boolean,
    filtering?: boolean,
    datasource?: any
  ) => {
    result.rowStyle = child.props.style;
    result.rowRenderer = (props: any) => {
      return React.cloneElement(child, props);
    };
    React.Children.forEach(child.props.children, (grandChild, grandChildIndex) => {
      const columnProps: TableColumnProps & CommonProps = _.clone(grandChild.props);
      const props = {};
      Object.keys(grandChild.props).forEach((key) => {
        if (key !== "children" || typeof grandChild.props[key] === "string") {
          props[key] = grandChild.props[key];
        }
      });
      columnProps.render = (value: any, rowData: any, rowIndex: number) => {
        let children = grandChild?.props?.children;
        if (grandChild?.props?.children?.length === 2 && grandChild.props.isEditableColumn) {
          children = [];
          if (grandChild.props.isEditableColumn) {
            if (_nextProps.editableAlwaysActive) {
              children.push(grandChild.props.children[1]);
            } else if (result.activeEditableRowIndex === rowIndex) {
              children.push(grandChild.props.children[1]);
            } else {
              children.push(grandChild.props.children[0]);
            }
          }
        }
        return (
          <>{React.cloneElement(grandChild, BaseTable.withColumnProps(props, value, rowData, rowIndex), children)}</>
        );
      };
      delete columnProps.children;
      if (
        columnProps &&
        result &&
        result.columnTitles &&
        result.columnTitles.length > grandChildIndex &&
        result.columnTitles[grandChildIndex]?.length > 0
      ) {
        columnProps.title = result.columnTitles[grandChildIndex];
      }

      let value =
        grandChild.props?.children?.props?.value ??
        grandChild.props?.children?.props?.children?.props?.value ??
        grandChild.props?.children?.props?.children?.props?.children?.props?.value;
      if (grandChild.props?.children?.props?.className === "kFormItem" && !value?.toString().includes("[datafield:")) {
        value = undefined;
      }
      if (value === undefined && grandChild.props?.children && grandChild.props?.children.length > 0) {
        value =
          grandChild.props?.children[0]?.props?.value ?? grandChild.props?.children[0]?.props?.children?.props?.value;
      }
      const dataBindedChilds = [];

      const processChildren = (children) => {
        if (Array.isArray(children)) {
          children.forEach((child) => {
            if (child.props?.children) {
              processChildren(child.props.children);
            }
            Object.keys(child.props).forEach((key) => {
              if (
                child.props[key]?.toString().includes("[datafield:") &&
                key !== "visExField1" &&
                key !== "visExField2"
              ) {
                dataBindedChilds.push(child.props[key]);
              }
            });
          });
        } else if (children) {
          if (children.props?.children) {
            processChildren(children.props.children);
          }
          if (children.props) {
            Object.keys(children.props).forEach((key) => {
              if (
                children.props[key]?.toString().includes("[datafield:") &&
                key !== "visExField1" &&
                key !== "visExField2"
              ) {
                dataBindedChilds.push(children.props[key]);
              }
            });
          }
        }
      };

      if (grandChild.props?.children?.props?.className !== "kFormItem") {
        processChildren(grandChild.props?.children);
      }

      if (value === undefined && grandChild.props?.children?.props?.className !== "kFormItem") {
        processChildren(grandChild.props?.children?.props?.children);
      } else if (value === undefined && grandChild.props?.children?.props?.children?.props.className !== "kFormItem") {
        processChildren(grandChild.props?.children?.props?.children?.props.children);
      }

      if (
        dataBindedChilds.length === 1 ||
        (dataBindedChilds.length > 1 &&
          dataBindedChilds.every((dataBindedChild) => dataBindedChild === dataBindedChilds[0]))
      ) {
        value = dataBindedChilds[0];
      } else if (
        dataBindedChilds.length > 1 &&
        !dataBindedChilds.every((dataBindedChild) => dataBindedChild === dataBindedChilds[0])
      ) {
        value = undefined;
      }

      if (
        dataBindedChilds.length > 1 &&
        !dataBindedChilds.every((dataBindedChild) => dataBindedChild === dataBindedChilds[0]) &&
        columnProps.defaultDataField
      ) {
        value = columnProps.defaultDataField?.toLowerCase();
      }
      if (value && !value?.toString().includes("[datafield:") && !columnProps.defaultDataField) {
        value = undefined;
      }
      if (grandChild.props?.children?.props?.children?.props?.checked) {
        value = grandChild.props?.children?.props?.children?.props?.checked;
      }

      if (
        !value &&
        grandChild.props?.children &&
        grandChild.props?.children.length > 1 &&
        grandChild.props?.children?.some(
          (child) =>
            child?.props?.visibility === "hidden" &&
            (grandChild.props?.children?.some((child) => !child?.props?.visibility) ||
              grandChild.props?.children?.some((child) => child?.props?.visibility === "visible"))
        )
      ) {
        const visibleColumnValue =
          grandChild.props?.children?.find((child) => !child?.props?.visibility)?.props?.value ||
          grandChild.props?.children?.find((child) => child?.props?.visibility === "visible")?.props?.value;
        value = visibleColumnValue;
      }
      if (value) {
        value = value.toString().replace("[datafield:", "").replace("]", "");
        let dataType = ValueType.String;

        function getDataTypeRecursively(datasource, value) {
          if (!datasource || datasource.length === 0) {
            return null;
          }

          if (
            datasource[0] !== null &&
            datasource[0] !== undefined &&
            datasource[0][value] !== undefined &&
            datasource[0][value] !== null
          ) {
            return BaseTable.getValueType(datasource[0][value]);
          }
          return getDataTypeRecursively(datasource?.slice(1), value);
        }
        dataType = getDataTypeRecursively(datasource?.slice(0, 10), value);

        if (sorter && columnProps?.hideSorting !== true) {
          columnProps.sorter = (a, b) => BaseTable.compare(value, a, b);
        }
        if (filtering && columnProps?.hideFiltering !== true) {
          let initialFilters: any = {};

          if (result.filteringOptions) {
            Object.values(result.filteringOptions).forEach((filters: any) => {
              if (filters && filters.length > 0 && filters[0].columnId === columnProps.id) {
                initialFilters = filters[0];
              }
            });
          }

          columnProps.filteredValue =
            initialFilters.value ||
            initialFilters.value === false ||
            initialFilters.type === "blank" ||
            initialFilters.type === "not_blank" ||
            initialFilters.type === "number_blank" ||
            initialFilters.type === "number_not_blank" ||
            initialFilters.type === "date_blank" ||
            initialFilters.type === "date_not_blank" ||
            initialFilters.type === "boolean_blank" ||
            initialFilters.type === "boolean_not_blank"
              ? [initialFilters]
              : [];

          columnProps.filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters, visible }) =>
            filtering && (
              <CustomFilterDropdown
                selectedKeys={selectedKeys[0]}
                setSelectedKeys={(text) => setSelectedKeys([text])}
                confirm={confirm}
                clearFilters={clearFilters}
                dataType={dataType}
                visible={visible}
                initialFilters={initialFilters}
                columnId={columnProps.id}
              />
            );
          columnProps.onFilter = (searchValue: any, record) => {
            return BaseTable.applyFilter(searchValue, record, value, dataType);
          };
        }
        (columnProps as any).dataType = dataType;
        (columnProps as any).dataValue = value;
      }

      result?.columns?.push(columnProps);
    });
  };

  static applyFilter = (searchValue: any, record: any, value: any, dataType: any) => {
    const { type } = searchValue;
    const recordValue = record[value];

    const valueType = BaseTable.getValueType(recordValue, dataType);

    if (valueType === ValueType.String || !valueType) {
      const normalizedRecordValue = recordValue?.toString()?.toLowerCase();
      const normalizedSearchValue =
        type === "blank" || type === "number_blank" || type === "date_blank" || type === "boolean_blank"
          ? null
          : searchValue?.value?.toLowerCase();

      switch (type) {
        case StringFilterTypes.Contains:
          return normalizedRecordValue?.includes(normalizedSearchValue);
        case StringFilterTypes.DoesNotContain:
          return !normalizedRecordValue?.includes(normalizedSearchValue);
        case StringFilterTypes.Equals:
          return normalizedRecordValue === normalizedSearchValue;
        case StringFilterTypes.DoesNotEqual:
          return normalizedRecordValue !== normalizedSearchValue;
        case StringFilterTypes.BeginsWith:
          return normalizedRecordValue?.startsWith(normalizedSearchValue);
        case StringFilterTypes.EndsWith:
          return normalizedRecordValue?.endsWith(normalizedSearchValue);
        case StringFilterTypes.Blank:
          return !recordValue;
        case StringFilterTypes.NotBlank:
          return !!recordValue;
        default:
          return normalizedRecordValue?.includes(normalizedSearchValue);
      }
    } else if (valueType === ValueType.Number) {
      const recordValueNumber = Number(recordValue);
      const searchValueNumber = Number(searchValue?.value);

      switch (type) {
        case NumberFilterTypes.Equals:
          return recordValueNumber === searchValueNumber;
        case NumberFilterTypes.DoesNotEqual:
          return recordValueNumber !== searchValueNumber;
        case NumberFilterTypes.GreaterThan:
          return recordValueNumber > searchValueNumber;
        case NumberFilterTypes.LessThan:
          return recordValueNumber < searchValueNumber;
        case NumberFilterTypes.GreaterThanOrEqual:
          return recordValueNumber >= searchValueNumber;
        case NumberFilterTypes.LessThanOrEqual:
          return recordValueNumber <= searchValueNumber;
        case NumberFilterTypes.Between:
          return recordValueNumber >= searchValueNumber[0] && recordValueNumber <= searchValueNumber[1];
        case NumberFilterTypes.Blank:
          return !recordValue && recordValue !== 0;
        case NumberFilterTypes.NotBlank:
          return !!recordValue || recordValue === 0;
        default:
          return recordValueNumber === searchValueNumber;
      }
    } else if (valueType === ValueType.Date) {
      const recordValueDate = moment(recordValue);
      const searchValueDate = moment(searchValue?.value);
      const searchValueDate2 = moment(searchValue?.value2);

      switch (type) {
        case DateFilterTypes.Equals:
          return recordValueDate?.isSame(searchValueDate, "day");
        case DateFilterTypes.DoesNotEqual:
          return !recordValueDate?.isSame(searchValueDate, "day");
        case DateFilterTypes.After:
          return recordValueDate?.isAfter(searchValueDate, "day");
        case DateFilterTypes.Before:
          return recordValueDate?.isBefore(searchValueDate, "day");
        case DateFilterTypes.Between:
          return recordValueDate?.isBetween(searchValueDate, searchValueDate2, "day", "[]");
        case DateFilterTypes.Blank:
          return !recordValue;
        case DateFilterTypes.NotBlank:
          return !!recordValue;
        default:
          return recordValueDate.isSame(searchValueDate, "day");
      }
    } else if (valueType === ValueType.Boolean) {
      const normalizedRecordValue = recordValue;
      const normalizedSearchValue =
        type === "blank" || type === "number_blank" || type === "date_blank" || type === "boolean_blank"
          ? null
          : searchValue?.value;

      switch (type) {
        case BooleanFilterTypes.Equals:
          return normalizedRecordValue === normalizedSearchValue;
        case BooleanFilterTypes.DoesNotEqual:
          return normalizedRecordValue !== normalizedSearchValue;
        case BooleanFilterTypes.Blank:
          return !recordValue && recordValue !== false;
        case BooleanFilterTypes.NotBlank:
          return !!recordValue || recordValue === false;
        default:
          return normalizedRecordValue === normalizedSearchValue;
      }
    } else {
      return false;
    }
  };

  static isNotNull = (value: any) => {
    if (value === undefined) return false;
    if (value === null) return false;
    return true;
  };

  static compare = (value, a, b) => {
    let x = "";
    if (a?.id != null && Object.keys(a).every((key) => key === "id" || a[key] == null)) {
      return 0;
    }
    if (b?.id != null && Object.keys(b).every((key) => key === "id" || b[key] == null)) {
      return 0;
    }
    if (BaseTable.isNotNull(a) && BaseTable.isNotNull(value) && BaseTable.isNotNull(a[value])) {
      x = a[value];
      if (typeof a[value] === "string") {
        x = a[value].trim();
      }
    }
    if (!BaseTable.isNotNull(x)) {
      x = "";
    }

    let y = "";
    if (BaseTable.isNotNull(b) && BaseTable.isNotNull(value) && BaseTable.isNotNull(b[value])) {
      y = b[value];
      if (typeof b[value] === "string") {
        y = b[value].trim();
      }
    }

    if (!BaseTable.isNotNull(y)) {
      y = "";
    }
    const specialSortingFormats = [
      "DD/MM/YYYY HH:mm",
      "DD/MM/YYYY HH:mm:ss",
      "DD/MM/YYYY",
      "MM/DD/YYYY HH:mm",
      "MM/DD/YYYY HH:mm:ss",
      "MM/DD/YYYY",
      "HH:mm:ss",
      "HH:mm",
      moment.ISO_8601
    ];

    const isValidDate = BaseTable.isDate(x, specialSortingFormats);

    if (isValidDate) {
      const specialSortingFormat = specialSortingFormats.find((format) => moment(x, format, true).isValid());
      if (specialSortingFormat) {
        if (!x || x === "") {
          return 0;
        }
        if (!y || y === "") {
          return 1;
        }
        const xDate = moment(x, specialSortingFormat);
        const yDate = moment(y, specialSortingFormat);
        return xDate.diff(yDate);
      }
    }

    if (
      (BaseTable.isNumber(x, true) || x === "" || x === undefined || x === null) &&
      (BaseTable.isNumber(y, true) || y === "" || y === undefined || y === null)
    ) {
      if ((!x && parseInt(x) !== 0) || x === "") {
        return -1;
      }
      if ((!y && parseInt(y) !== 0) || y === "") {
        return 1;
      }
      return Number(x) - Number(y);
    }

    if (typeof x === "string" && typeof y === "string" && x.localeCompare) {
      return x.localeCompare(y);
    }

    if (typeof x === "boolean" && typeof y === "boolean") {
      return x === y ? 0 : x ? 1 : -1;
    }

    return 0;
  };

  static isDate = (value: any, formats: any[]) => {
    if (value === undefined || value === null) return false;
    if (typeof value === "string") {
      for (const format of formats) {
        if (moment(value, format, true).isValid()) {
          return true;
        }
      }
    } else if (typeof value === "number") {
      return false;
    }

    return false;
  };

  static isNumber(r, isSort) {
    if (typeof r === "string" && !isSort) return false;
    return /^\d+(\.\d+)?$/.test(r);
  }

  static getValueType(value: any, dataType?: ValueType) {
    const specialSortingFormats = [
      "DD/MM/YYYY HH:mm",
      "DD/MM/YYYY HH:mm:ss",
      "DD/MM/YYYY",
      "MM/DD/YYYY HH:mm",
      "MM/DD/YYYY HH:mm:ss",
      "MM/DD/YYYY",
      "HH:mm:ss",
      "HH:mm",
      moment.ISO_8601
    ];
    if (typeof value === "boolean" || value === "true" || value === "false") {
      return ValueType.Boolean;
    }
    if (BaseTable.isDate(value, specialSortingFormats)) {
      return ValueType.Date;
    }
    if (BaseTable.isNumber(value, false)) {
      return ValueType.Number;
    }
    if (!value || value === "" || value === null || value === undefined) {
      return dataType;
    }
    return ValueType.String;
  }

  static setGalleryItem = (result: Partial<ITableState>, children: any) => {
    result.rowRenderer = (props: any) => {
      return <GalleryItem {...props}></GalleryItem>;
    };
    const columnProps: TableDataProps & CommonProps = {};
    columnProps.render = (_value: any, rowData: any, rowIndex: number) => {
      return (
        <TableData rowIndex={rowIndex} rowData={rowData}>
          {children}
        </TableData>
      );
    };
    result?.columns?.push(columnProps);
  };

  static setCarouselItem = (result: Partial<ITableState>, children: any) => {
    result.rowRenderer = (props: any) => {
      return <CarouselItem {...props}></CarouselItem>;
    };
    const columnProps: TableDataProps & CommonProps = {};

    columnProps.render = (_value: any, rowData: any, rowIndex: number) => (
      <TableData rowIndex={rowIndex} rowData={rowData}>
        {children}
      </TableData>
    );
    result?.columns?.push(columnProps);
  };

  static setGoogleMapsItem = (result: Partial<ITableState>, children: any, nextProps: ITableProps) => {
    let rowPopoverRenderer: any | undefined;

    React.Children.forEach(nextProps.children, (child, childIndex) => {
      switch (childIndex) {
        // GoogleMapsMarkerPopup
        case 1:
          rowPopoverRenderer = (_value: any, rowData: any, rowIndex: number) => {
            return (
              <TableData
                rowIndex={rowIndex}
                rowData={rowData}
                markerDetailHeight={nextProps.markerDetailHeight}
                markerDetailWidth={nextProps.markerDetailWidth}
              >
                {React.cloneElement(
                  child,
                  {
                    ...child.props,
                    markerDetailWidth: nextProps.markerDetailWidth,
                    markerDetailHeight: nextProps.markerDetailHeight
                  },
                  child.props.children
                )}
              </TableData>
            );
          };
      }
    });

    result.rowRenderer = (props: any) => {
      return <GoogleMapsItem {...props} rowPopoverRenderer={rowPopoverRenderer}></GoogleMapsItem>;
    };
    const columnProps: TableDataProps & CommonProps = {};

    columnProps.render = (_value: any, rowData: any, rowIndex: number) => (
      <TableData rowIndex={rowIndex} rowData={rowData}>
        {children}
      </TableData>
    );
    result?.columns?.push(columnProps);
  };

  static refreshGoogleMapsItem: ((nextProps?: any, callBack?: any) => void) | undefined;

  static setFlexGridItem = (result: Partial<ITableState>, children: any) => {
    result.rowRenderer = (props: any) => {
      return <FlexGridItem {...props}></FlexGridItem>;
    };
    const columnProps: TableDataProps & CommonProps = {};

    columnProps.render = (_value: any, rowData: any, rowIndex: number) => (
      <TableData rowIndex={rowIndex} rowData={rowData}>
        {children}
      </TableData>
    );
    result?.columns?.push(columnProps);
  };

  setStyle = (_props?: ITableProps) => {
    const node: Element | null | Text = ReactDOM.findDOMNode(this);
    if (!node) {
      return;
    }
    const table = (node as Element).querySelectorAll("div > div > div > div > div > table");
    this.setBackgroundColor(table);
    const tbody = (node as Element).querySelectorAll("div > div > div > div > div > tbody");
    this.setBackgroundColor(tbody);
    const tdList = (node as Element).querySelectorAll("div > div > div > div > div > table > tbody > tr > td");
    tdList.forEach((td: any, index: number) => {
      this.setBackgroundColor(td);
      if (this.props.mode === "table") {
        this.setAlignments(td, index);
      }
    });
    if (this.props.mode === "table") {
      this.setDynamicStyle();
    }
  };

  getDynamicCss = (): string => {
    const className: string = this.getClassName();
    if (!className || className.length === 0) {
      return "";
    }
    let result = "";
    const { maxHeight, boxShadow, height, minHeight, padding, paddingRight, paddingLeft, paddingTop, paddingBottom } =
      this.props.style;
    if (maxHeight || height || minHeight) {
      if (height) {
        result += `.${className.trim()} .ant-table-wrapper {
              height: ${height} !important;
            }`;
        result += `.${className.trim()} .ant-table-content table {
              min-height: ${height} !important;
            }`;
      }
      if (minHeight) {
        result += `.${className.trim()} .ant-table-wrapper {
              min-height: ${minHeight} !important;
            }`;
      }
      if (maxHeight) {
        result += `.${className.trim()} .ant-table-wrapper {
                max-height: ${maxHeight} !important;
                overflow-y: scroll !important;
              }`;
      }
      if (height || maxHeight) {
        if (this.props.pagination) {
          result += `.${className.trim()} .ant-pagination {
              background: white;
              position: sticky;
              bottom: 0;
              margin-bottom: 0 !important;
              height: 36px;
              align-items: center;
              margin-right: 2px !important;
                }`;
        }
      }
    }

    if (this.props.fixedHeader) {
      result += `.${className.trim()} .ant-table-thead {
            position: sticky;
            top: 0;
            background: #fff;
            z-index: 3;
        }`;
    }

    if (this.props.searchable && this.props.sorter && !this.props.filtering) {
      result += `.${className.trim()} .ant-table-thead > tr > th > .ant-table-column-sorters {
            padding-bottom: 36px !important
        }`;
    }

    if (boxShadow) {
      result += `.${className.trim()} {
          box-shadow: ${boxShadow} !important;
        }`;
    }

    if (this.props?.striped) {
      result += `.${className.trim()} table tr:nth-child(2n) td {
            background-color: #F5F7FA;
        }`;
    }

    if (this.props?.striped && this.props?.stripedColor) {
      result += `.${className.trim()} table tr:nth-child(2n) td {
            background-color: ${this.props?.stripedColor};
        }`;
    }

    if (
      (padding || paddingRight || paddingLeft || paddingTop || paddingBottom) &&
      window?.kuika?.dashboardState === DashboardState.reportDesigner
    ) {
      result += `.${className.trim()} .ant-table {
          padding: ${paddingTop ?? 0}px ${paddingRight ?? 0}px ${paddingBottom ?? 0}px ${paddingLeft ?? 0}px !important;
        }`;
    }

    result += `.${className.trim()} .ant-table-wrapper {
      overflow: auto !important;
    }`;
    return result;
  };

  setDynamicStyle = () => {
    const uniquekey = this.state.uniqueKey?.toString();
    if (!uniquekey) {
      return;
    }
    const isDesignTime = window.kuika?.isDesignTime;
    if (this.memoizedDynamicCssResult !== "" && !isDesignTime) {
      return this.memoizedDynamicCssResult;
    }
    const dynamic_style = document.getElementById("dynamic_style");
    if (dynamic_style && dynamic_style.innerHTML?.indexOf(uniquekey) === -1) {
      const generatedCss = this.getDynamicCss();
      dynamic_style.innerHTML = `${dynamic_style.innerHTML}
        ${generatedCss}`;
      this.memoizedDynamicCssResult = generatedCss;
    }
  };

  getClassName = () => {
    let result = "";
    if (!this.state.uniqueKey) {
      return result;
    }
    result = `${result} ktable_${this.state.uniqueKey.toString().substring(0, 8)}`;
    return result;
  };

  hideNoDataIfNotNeeded = (props: any) => {
    if (!props.showNoDataFound) {
      const node = document.getElementById(props.id);
      if (node) {
        const empty: HTMLCollectionOf<Element> = node.getElementsByClassName("ant-empty");
        if (empty.length > 0) {
          (empty[0] as HTMLElement).style.display = "none";
        }
      }
    }
  };

  setBackgroundColor = (element: any, props?: any) => {
    if (props && props.style && props.style.backgroundColor) {
      element.style["background-color"] = props.style.backgroundColor;
    }
  };

  setAlignments = (element: any, index: number) => {
    const isDesignTime = window.kuika?.isDesignTime;
    let columnStyles: React.CSSProperties = {};
    if (this.props?.children[1]?.props?.children?.length > 0) {
      const columnIndex = index % this.props?.children[1]?.props?.children.length;
      columnStyles = this.props?.children[1]?.props?.children[columnIndex]?.props?.style;
    }
    let node: any = element.firstChild;
    if (!node) {
      return;
    }
    if (!isDesignTime) {
      node = element;
    }
    KMainFunctions.handleAlignments(node, columnStyles);
    if (!isDesignTime) {
      node.style.display = "table-cell";
      node.style.verticalAlign =
        columnStyles.alignItems === "center"
          ? "middle"
          : columnStyles.alignItems === "flex-end"
          ? "bottom"
          : columnStyles.alignItems === "flex-start"
          ? "top"
          : "center";
    }
  };

  componentDidUpdate = (prevProps: ITableProps) => {
    this.setStyle(prevProps);
    this.hideNoDataIfNotNeeded(prevProps);

    const isEql = isEqual(this.props.dataSource, prevProps.dataSource);
    if (this.state.activeEditableRowIndex != -1 && !isEql) {
      let formValues = {};
      let rowValue = this.getDataSource()[this.state.activeEditableRowIndex];
      if (rowValue) {
        Object.keys(rowValue).forEach((cellfieldName) => {
          let key = `Table_${this.props.id}_${cellfieldName}`;
          formValues[key] = rowValue[cellfieldName];
        });
        this.props.form?.setFieldsValue(formValues);
        this.setState({ randomGuid: Guid.create().toString() } as any, () => {
          this.setState({ activeEditableRowIndex: -1 } as any);
        });
      }
    }
    if (!isEql && this.props.editableAlwaysActive) {
      let formValues = {};
      this.props.dataSource?.forEach((rowValue, rowIndex) => {
        Object.keys(rowValue).forEach((cellfieldName) => {
          let key = `Table_${this.props.id}_${cellfieldName}`;
          formValues[key] = rowValue[cellfieldName];
        });
        this.props.form?.setFieldsValue(formValues);
        this.setState({ randomGuid: Guid.create().toString() } as any);
      });
    }
  };

  handleKeyDown = async (event: any) => {
    const isCurrentInsertRow = window.editingColumnData?.isInsertRow;
    if ((event.key === "Tab" || event.key === "Escape" || event.key === "Enter") && this.props.editableAlwaysActive) {
      if (event.key === "Escape") {
        this.setState({ activeEditableRowIndex: -1 } as any);
        return;
      }
      if (isCurrentInsertRow && event.key === "Tab") {
        window.editingColumnData = {
          ...window.editingColumnData,
          activeElement: event
        };
        return;
      }
      if (event.key === "Tab" || (event.key === "Enter" && !isCurrentInsertRow)) {
        this.setFields(event, false, window.editingColumnData?.rowData);
      }
      window.editingColumnData = {
        ...window.editingColumnData,
        isKeyDownTriggered: true
      };
      await this.handleOnRowEditFinished();
      if (isCurrentInsertRow && event.key === "Enter") {
        window.editingColumnData = {};
      }
    }
    if (event.key === "Escape") {
      if (this.state.activeEditableRowIndex != -1) {
        this.setState({ activeEditableRowIndex: -1 } as any);
      }
    }
    if (event.key === "Enter") {
      if (this.state.activeEditableRowIndex != -1 && this.props.onRowEditFinished) {
        this.handleOnRowEditFinished();
      }
    }
  };

  handleClick = (event: any) => {
    if (
      event.target.id.toString().includes("body") ||
      event.target.id.toString().includes("header") ||
      event.target.id.toString().includes("footer")
    )
      if (this.state.activeEditableRowIndex != -1 && this.props.onRowEditFinished) {
        this.handleOnRowEditFinished();
      }
  };

  componentWillUnmount(): void {
    document.removeEventListener("keydown", this.handleKeyDown);
    document.removeEventListener("click", this.handleClick);
    isEventListenerAdded = false;
  }

  componentDidMount = () => {
    this.setStyle(this.props);
    this.hideNoDataIfNotNeeded(this.props);
    this.setState({ pageSize: this.props.pageSize && this.props.pageSize > 1 ? this.props.pageSize : 10 });
    if (!isEventListenerAdded) {
      document.addEventListener("keydown", this.handleKeyDown);
      document.addEventListener("click", this.handleClick);
      isEventListenerAdded = true;
    }
    const selectedRowKeys = this.props.selectedValues ? (this.props.selectedValues as any)?.split(",") : [];
    this.setState({ selectedRowKeys, selectedRowKeysString: this.props.selectedValues || "" });
  };

  getRowBgColor = (rowData: any, rowIndex?: number): string | undefined => {
    if (this.state.selectedRowKeys.includes(rowData.id) && this.props.selectedRowColor) {
      return this.props.selectedRowColor;
    }
    if (this.props.rowBgColor) {
      return this.props.rowBgColor(rowData, rowIndex);
    }
    return undefined;
  };

  getRowFontColor = (rowData: any, rowIndex?: number): string | undefined => {
    if (this.props.rowFontColor) {
      return this.props.rowFontColor(rowData, rowIndex);
    }
    return undefined;
  };

  getRowStyle = (rowValue: any, index: number | undefined): any | undefined => {
    let style: any | undefined = _.clone(this.state.rowStyle);
    if (!style) {
      style = {};
    }
    const color = this.getRowFontColor(rowValue, index);
    if (color) {
      style.color = color;
    }
    const backgroundColor = this.getRowBgColor(rowValue, index);
    if (backgroundColor) {
      style.backgroundColor = backgroundColor;
    }
    return style;
  };

  getViewWrapper = () => {
    switch (this.props.mode) {
      case "table":
        return (props) => (
          <TableViewWrapper
            {...props}
            style={this.props.style}
            scrollable={this.props.tableWidthMode === "horizontal scroll" || this.props.tableWidthMode === "scroll"}
          ></TableViewWrapper>
        );
      case "carousel":
        return (props) => <CarouselViewWrapper {...props} style={this.props.style}></CarouselViewWrapper>;
      case "google-maps":
        return (props) => <GoogleMapsViewWrapper {...props} style={this.props.style}></GoogleMapsViewWrapper>;
      case "gallery":
        return (props) => <GalleryViewWrapper {...props} style={this.props.style}></GalleryViewWrapper>;
      case "flex-grid":
        return (props) => <FlexGridViewWrapper {...props} style={this.props.style} />;
    }
  };

  refreshGoogleMapsBodyWrapper: ((nextProps?: any, callBack?: any) => void) | undefined;

  getBodyWrapper = () => {
    switch (this.props.mode) {
      case "table":
        return TableBodyWrapper;
      case "carousel":
        return (props) => (
          <CarouselBodyWrapper
            {...props}
            {...this.getCarouselBodyWrapperProps()}
            setCurrentSlideNumber={(val: any) => {
              this.setState({ carouselStartingIndex: val });
            }}
            startingIndex={this.state.carouselStartingIndex}
          />
        );
      case "google-maps":
        return (props) => (
          <RefreshDelegate
            {...props}
            setRefreshDelegate={(refreshMethod) => {
              this.refreshGoogleMapsBodyWrapper = refreshMethod;
            }}
            {...this.getGoogleMapsBodyWrapperProps()}
            Component={GoogleMapsBodyWrapper}
          />
        );
      case "gallery":
        return (props) => <GalleryBodyWrapper {...props} {...this.getGalleryBodyWrapperProps()}></GalleryBodyWrapper>;
      case "flex-grid":
        return (props) => <FlexGridBodyWrapper {...props} {...this.getGalleryBodyWrapperProps()} />;
    }
  };

  getBodyCell = () => {
    switch (this.props.mode) {
      case "table":
        return TableBodyCell;
      case "carousel":
        return CarouselBodyCell;
      case "google-maps":
        return GoogleMapsBodyCell;
      case "gallery":
        return GalleryBodyCell;
      case "flex-grid":
        return FlexGridBodyCell;
    }
  };

  getCarouselBodyWrapperProps = () => {
    const result: CarouselBodyWrapperProps = {};
    const p = this.props as CarouselBodyWrapperProps;
    if (p.autoplaySpeed) result.autoplaySpeed = p.autoplaySpeed;
    if (p.autoplay) result.autoplay = p.autoplay;
    if (p.fade) result.fade = p.fade;
    if (p.pauseOnDotsHover) result.pauseOnDotsHover = p.pauseOnDotsHover;
    if (p.slidesToScroll) result.slidesToScroll = p.slidesToScroll;
    if (p.dots === false) {
      result.dots = false;
    }
    if (p.dots === undefined || p.dots === true) {
      result.dots = true;
    }
    if (p.slidesToShow) result.slidesToShow = p.slidesToShow;
    if (p.speed) result.speed = p.speed;
    if (p.swipe) result.swipe = p.swipe;
    if (p.cssEase) result.cssEase = p.cssEase;
    if (p.vertical) result.vertical = p.vertical;
    if (p.onChange) result.onChange = p.onChange;
    if (p.startingIndex && p.startingIndex > 0) {
      result.startingIndex = p.startingIndex;
      this.setState({ carouselStartingIndex: p.startingIndex as any });
    }
    if (p.hoverBgColor) result.hoverBgColor = p.hoverBgColor;
    if (p.hoverFontColor) result.hoverFontColor = p.hoverFontColor;
    return result;
  };

  getLatValue = () => {
    if (this.lastSelectedLat !== undefined) {
      return this.lastSelectedLat;
    }
    return "";
  };

  getLngValue = () => {
    if (this.lastSelectedLng !== undefined) {
      return this.lastSelectedLng;
    }
    return "";
  };

  getGoogleMapsBodyWrapperProps = () => {
    const result: GoogleMapsBodyWrapperProps = {} as any;
    const p = this.props;
    result.value = this.state.value;
    if (p.style) result.style = p.style;
    if (p.latitude) result.latitude = p.latitude;
    if (p.longitude) result.longitude = p.longitude;
    if (p.zoom) result.zoom = p.zoom;
    if (p.dataLatField) result.dataLatField = p.dataLatField;
    if (p.dataLngField) result.dataLngField = p.dataLngField;
    if (p.markerDetailView) result.markerDetailView = p.markerDetailView;
    if (p.markerDetailPosition) result.markerDetailPosition = p.markerDetailPosition;
    if (p.markerDetailHeight) result.markerDetailHeight = p.markerDetailHeight;
    if (p.markerDetailWidth) result.markerDetailWidth = p.markerDetailWidth;
    if (p.designClicked) result.designClicked = p.designClicked;
    result.isPicker = p.isPicker;
    result.isMarkerPicker = p.isMarkerPicker;
    result.hasSearchBox = p.hasSearchBox;
    result.disableDoubleClickZoom = p.disableDoubleClickZoom;
    result.fullscreenControl = p.fullscreenControl;
    result.streetViewControl = p.streetViewControl;
    result.scaleControl = p.scaleControl;
    result.zoomControl = p.zoomControl;
    result.panControl = p.panControl;
    result.rotateControl = p.rotateControl;
    result.mapTypeControl = p.mapTypeControl;
    result.autoCenter = p.autoCenter;
    result.circleRadius = p.circleRadius;
    result.clearActiveContent = () => {
      if (this.refreshGoogleMapsBodyWrapper) {
        this.refreshGoogleMapsBodyWrapper({ activeContent: undefined, designClicked: false });
      }
    };
    result.onChange = (isDataMarkerSelected: boolean, value?: ICoords) => {
      this.setState({ value, isDataMarkerSelected } as any, () => {
        if (this.refreshGoogleMapsBodyWrapper) {
          let wrapperProps: any = { value, isDataMarkerSelected };
          if (isDataMarkerSelected !== true) {
            wrapperProps = { value, isDataMarkerSelected, activeContent: undefined };
          }
          this.refreshGoogleMapsBodyWrapper(wrapperProps, () => {
            if (p.onChange) {
              this.lastSelectedLat = value?.latitude;
              this.lastSelectedLng = value?.longitude;
              p.onChange(value);
            }
          });
        }
      });
    };
    result.onGoogleApiLoaded = (map: any, maps: any) => {
      this.setState({ map, maps }, () => {
        if (this.refreshGoogleMapsBodyWrapper) {
          this.refreshGoogleMapsBodyWrapper({ map, maps }, () => {});
        }
      });
    };
    result.maps = this.state.maps;
    result.map = this.state.map;
    result.isDataMarkerSelected = this.state.isDataMarkerSelected;
    result.groupPickerIcon = p.groupPickerIcon;
    result.groupPickerColor = p.groupPickerColor;
    result.currentLocationIcon = p.currentLocationIcon;
    result.currentLocationColor = p.currentLocationColor;
    return result;
  };

  getGalleryBodyWrapperProps = () => {
    const result: GalleryBodyWrapperProps = {};
    const p = this.props as GalleryBodyWrapperProps;
    if (p.horizontalGutter) result.horizontalGutter = p.horizontalGutter;
    if (p.verticalGutter) result.verticalGutter = p.verticalGutter;
    return result;
  };

  public getSelectedIndex = () => {
    const selectedIndexHiddenInput = this.getSelectedRowIndexHiddenInput();

    if (selectedIndexHiddenInput) {
      return selectedIndexHiddenInput.value;
    }
  };

  public getSelectedValues = () => {
    return this.state.selectedRowKeysString;
  };

  getNestedTableSelectedIndex = (parentTableDomEl: HTMLElement) => {
    const selectedIndex = this.getSelectedIndex();

    const tbody = parentTableDomEl.querySelector("tbody");

    if (!tbody) {
      return;
    }

    const rows = tbody.querySelectorAll(":scope > tr");

    if (rows.length === 0) {
      return;
    }

    if (selectedIndex === undefined || selectedIndex === "") {
      return;
    }

    const selectedRow = rows[selectedIndex];

    const selectedIndexDom = selectedRow.querySelector(".selected-index-guid");

    if (!(selectedIndexDom instanceof HTMLInputElement)) {
      return;
    }

    return selectedIndexDom.value ?? "";
  };

  getTableWrapperFromHtmlElement = (htmlElementNode: HTMLElement): HTMLElement | undefined => {
    while (htmlElementNode) {
      htmlElementNode = htmlElementNode.parentNode as HTMLElement;

      if (htmlElementNode?.className?.includes("ant-table-middle")) {
        return htmlElementNode;
      }
    }

    return undefined;
  };

  getNearestTableAncestor = (htmlElementNode: HTMLElement): HTMLElement | undefined => {
    while (htmlElementNode) {
      htmlElementNode = htmlElementNode?.parentNode as HTMLElement;

      if (htmlElementNode?.tagName?.toLowerCase() === "table") {
        return htmlElementNode;
      }
    }

    return undefined;
  };

  public getParentTableIdIfExist = () => {
    const currentTableElement = document.getElementById(this.props.id);
    const nearestTableAncestor = this.getNearestTableAncestor(currentTableElement);

    if (!nearestTableAncestor) {
      return;
    }

    const tableWrapper = this.getTableWrapperFromHtmlElement(nearestTableAncestor);

    return tableWrapper.id;
  };

  getSelectedColumnIndex = () => {
    const selectedIndexHiddenInput = this.getSelectedColumnIndexHiddenInput();
    if (selectedIndexHiddenInput) {
      return selectedIndexHiddenInput.value;
    }
  };

  public getSelectedRowData = () => {
    const selectedRowDataHiddenInput = this.getSelectedRowDataHiddenInput();
    if (!this.props.dataSource || !selectedRowDataHiddenInput || !selectedRowDataHiddenInput.value) {
      return;
    }
    const isPrevInsertRow = this.isInsertRow(window.editingColumnData?.prevRowData);
    if (
      isPrevInsertRow &&
      window.editingColumnData?.prevRowIndex?.toString() !== window.editingColumnData?.rowIndex?.toString()
    ) {
      const rowValue = window.editingColumnData?.prevRowData;
      return rowValue;
    }

    if (this.props.mode === "google-maps" && !this.state.isDataMarkerSelected) return;

    return JSON.parse(selectedRowDataHiddenInput.value);
  };

  public getCarouselCurrentData = () => {
    if (this.props.dataSource && this.props.mode === "carousel") {
      return this.props.dataSource[this.state.carouselStartingIndex];
    }
    return {} as any;
  };

  public getTableConfigJson = () => {
    const json = {
      searchText: this.state.searchText,
      columnSearchTexts: this.state.columnSearchTexts,
      hiddenColumnList: this.state.hiddenColumnList,
      columnOrder: this.state.columnOrder,
      filteringOptions: this.state.filteringOptions,
      sorterOptions: this.state.sorterOptions,
      columnWidths: this.state.columnWidths
    };
    return JSON.stringify(json);
  };

  public setTableConfigJson = (json: string) => {
    if (!json || json === "") return;
    const obj = JSON.parse(json);
    this.setState({
      searchText: obj.searchText,
      columnSearchTexts: obj.columnSearchTexts,
      hiddenColumnList: obj.hiddenColumnList,
      columnOrder: obj.columnOrder,
      filteringOptions: obj.filteringOptions,
      sorterOptions: obj.sorterOptions,
      columnWidths: obj.columnWidths
    });
  };

  public clearFilterConfigs = () => {
    this.setState({
      searchText: "" as any,
      columnSearchTexts: {} as any,
      filteringOptions: {},
      sorterOptions: {}
    });
  };

  public resetTableConfigs = () => {
    this.setState({
      searchText: "" as any,
      columnSearchTexts: {} as any,
      hiddenColumnList: [] as any,
      columnOrder: [] as any,
      filteringOptions: {} as any,
      sorterOptions: {} as any,
      columnWidths: {} as any
    });
  };

  getSelectedRowIndexHiddenInput = () => {
    const selectedIndexHiddenInput = window.document.getElementById(this.state.selectedIndexGuid);
    return selectedIndexHiddenInput;
  };

  getSelectedColumnIndexHiddenInput = () => {
    const selectedColumnIndexHiddenInput = window.document.getElementById(this.state.selectedColumnIndexGuid);
    return selectedColumnIndexHiddenInput;
  };

  getSelectedRowDataHiddenInput = () => {
    const selectedColumnIndexHiddenInput = window.document.getElementById(this.state.selectedRowDataGuid);
    return selectedColumnIndexHiddenInput;
  };

  handleOnRowEditFinished = (isBlur?: boolean) => {
    let oldValues = {};
    let newValues = {};
    let rowValue = window.editingColumnData?.rowData;

    if (!this.props.editableAlwaysActive) {
      rowValue = this.getDataSource()[this.state.activeEditableRowIndex];
    }

    if (rowValue) {
      Object.keys(rowValue).forEach((cellfieldName) => {
        let key = `Table_${this.props.id}_${cellfieldName}`;
        oldValues[key] = rowValue[cellfieldName];
        newValues[key] = this.props.form.getFieldValue(key);
      });
      const isEql = isEqual(oldValues, newValues);
      if (isEql) {
        this.setState({ activeEditableRowIndex: -1 } as any);
      } else {
        const idColumnKey = `Table_${this.props.id}_id`;
        if (oldValues[idColumnKey] !== newValues[idColumnKey]) {
          return;
        }
        this.props.onRowEditFinished();
      }
    }
    if (this.props.editableAlwaysActive) {
      const newVals = Object.keys(newValues).reduce((acc, key) => {
        acc[key.replace(`Table_${this.props.id}_`, "")] = newValues[key];
        return acc;
      }, {});
      const isInsertRow = this.props.insertRowActive && this.isInsertRow(window.editingColumnData?.rowData);
      window.editingColumnData = {
        rowIndex: window.editingColumnData?.rowIndex,
        columnIndex:
          window.editingColumnData?.isKeyDownTriggered && !isBlur
            ? window.editingColumnData?.columnIndex + 1
            : window.editingColumnData?.columnIndex,
        isInsertRow,
        isKeyDownTriggered: window.editingColumnData?.isKeyDownTriggered,
        prevRowIndex: undefined,
        rowData: newVals
      };
      console.log("roweditfinishedData", window.editingColumnData);
    }
  };

  handleRowClick = (_e: any, rowValue: any, rowIndex?: number) => {
    if (
      this.props.onRowEditFinished &&
      this.state.activeEditableRowIndex != -1 &&
      rowIndex != this.state.activeEditableRowIndex &&
      !this.props.editableAlwaysActive
    ) {
      this.handleOnRowEditFinished();
    }
    const selectedIndexHiddenInput = this.getSelectedRowIndexHiddenInput();
    if (selectedIndexHiddenInput && rowIndex !== undefined) {
      selectedIndexHiddenInput.value = rowIndex.toString();
    }
    const selectedRowDataHiddenInput = this.getSelectedRowDataHiddenInput();
    if (selectedRowDataHiddenInput && rowValue !== undefined) {
      selectedRowDataHiddenInput.value = JSON.stringify(rowValue);
    }
  };

  handleRowDoubleClick = (_e: any, rowValue: any, rowIndex?: number) => {
    if (this.props.editableAlwaysActive) {
      return;
    }
    if (this.state.activeEditableRowIndex != rowIndex && this.props.editable) {
      let formValues = {};
      Object.keys(rowValue).forEach((cellfieldName) => {
        let key = `Table_${this.props.id}_${cellfieldName}`;
        formValues[key] = rowValue[cellfieldName];
      });
      this.props.form?.setFieldsValue(formValues);
      this.setState({ activeEditableRowIndex: rowIndex } as any, () => {});
    }
    if (this.props.onRowDoubleClick) {
      this.props.onRowDoubleClick();
    }
  };

  handleOnRowBlur = (_e: any, _rowValue: any, _rowIndex?: number) => {};

  handleCellClick = (_e, columnIndex, rowData, rowIndex) => {
    console.log(rowData, rowIndex);
    if (this.props.editableAlwaysActive) {
      const currentRowIndex = _e.currentTarget.getAttribute("rowindex");
      const dataType: ValueType = _e.currentTarget.getAttribute("datatype");
      const isInsertRow = this.props.insertRowActive && this.isInsertRow(rowData);
      const prevRowIndex = window.editingColumnData?.rowIndex;
      const prevRowData = window.editingColumnData?.rowData;
      const prevActiveElement = window.editingColumnData?.activeElement;
      if (
        (isInsertRow &&
          window.editingColumnData?.rowIndex?.toString() !== currentRowIndex?.toString() &&
          currentRowIndex) ||
        (!isInsertRow &&
          (window.editingColumnData?.rowIndex?.toString() !== currentRowIndex?.toString() ||
            window.editingColumnData?.columnIndex?.toString() !== columnIndex?.toString()) &&
          currentRowIndex) ||
        !window.editingColumnData ||
        (dataType === ValueType.Boolean && !isInsertRow)
      ) {
        window.editingColumnData = {
          rowIndex: currentRowIndex,
          isInsertRow,
          columnIndex,
          activeElement: _e,
          prevRowIndex,
          rowData,
          prevRowData
        };
        console.log("cellclickData", window.editingColumnData);
        if (
          prevRowIndex?.toString() !== currentRowIndex?.toString() &&
          isInsertRow &&
          prevRowIndex?.toString() !== undefined &&
          currentRowIndex !== undefined
        ) {
          this.setFields(prevActiveElement, true, rowData);
          this.handleOnRowEditFinished();
          return;
        }

        this.setFields(_e, true, rowData);
      }
    }

    const selectedColumnIndexHiddenInput = this.getSelectedColumnIndexHiddenInput();
    if (selectedColumnIndexHiddenInput && columnIndex !== undefined) {
      selectedColumnIndexHiddenInput.value = columnIndex.toString();
    }
  };

  isInsertRow = (rowData) => {
    if (!rowData) {
      return false;
    }
    const otherFields = Object.keys(rowData).filter((key) => key !== "id");
    return otherFields.every((field) => rowData[field] === undefined);
  };

  setFields = (e: any, isClick: boolean, rowData) => {
    const isCurrentInsertRow = window.editingColumnData?.isInsertRow;
    const isPrevRowInsert = this.isInsertRow(window.editingColumnData?.prevRowData);
    if ((isCurrentInsertRow && !isClick) || isPrevRowInsert) {
      return;
    }
    let currentColumnDataValue = e.target.getAttribute("datavalue");
    let currentColumnDataType = e.target.getAttribute("datatype");
    let activeElement = e.target;
    let columnIndex = e.target.getAttribute("columnindex");
    if (window.editingColumnData?.columnIndex?.toString() !== columnIndex?.toString() && columnIndex) {
      return;
    }
    while (!currentColumnDataValue && activeElement && activeElement.getAttribute("datavalue") === null) {
      activeElement = activeElement.parentElement;
      currentColumnDataValue = activeElement?.getAttribute("datavalue");
      currentColumnDataType = activeElement?.getAttribute("datatype");
    }
    const dataSource = this.getDataSource();
    let selectedRow = rowData;
    let selectedColumnValue = selectedRow[currentColumnDataValue]?.toString();
    let currentColumnValue =
      e.target.value?.toString()?.trim() ||
      (document.activeElement as any).checked ||
      (document.activeElement as any).value;
    let isDate = false;
    let isBoolean = false;
    if (currentColumnValue && typeof currentColumnValue === "string" && currentColumnDataType === ValueType.Date) {
      currentColumnValue = moment(currentColumnValue, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");
      selectedColumnValue = moment(selectedColumnValue).format("YYYY-MM-DD HH:mm:ss");
      isDate = true;
    }
    if (
      e.target?.getAttribute("aria-checked") === "true" ||
      e.target?.getAttribute("aria-checked") === "false" ||
      e.target?.parentElement?.getAttribute("aria-checked") === "true" ||
      e.target?.parentElement?.getAttribute("aria-checked") === "false"
    ) {
      currentColumnValue = !(
        e.target.parentElement?.getAttribute("aria-checked") === "true" ||
        e.target?.getAttribute("aria-checked") === "true"
      );
      selectedColumnValue = selectedColumnValue === "true";
      isBoolean = true;
    }
    if (e.target.className === "ant-checkbox-inner" || e.target.className === "ant-checkbox-input") {
      const parent = e.target.parentElement;
      const input = parent?.querySelector("input");
      if (input) {
        currentColumnValue = input.getAttribute("checked") === null;
        selectedColumnValue = selectedColumnValue === "true";
        isBoolean = true;
      }
    }
    if (currentColumnValue && typeof currentColumnValue === "string") {
      currentColumnValue = currentColumnValue.replace(/[,]/g, "");
    }
    if (
      currentColumnValue !== selectedColumnValue &&
      currentColumnValue !== undefined &&
      currentColumnValue !== null &&
      currentColumnValue !== ""
    ) {
      selectedRow = {
        ...selectedRow,
        [currentColumnDataValue]:
          isDate || isBoolean
            ? currentColumnValue
            : currentColumnDataType === ValueType.Number
            ? parseFloat(currentColumnValue)
            : e.target.value
      };
      dataSource?.forEach((row, index) => {
        if (row.id === selectedRow.id) {
          dataSource[index] = selectedRow;
        }
      });
    }

    let formValues = {};
    Object.keys(selectedRow).forEach((cellfieldName) => {
      let key = `Table_${this.props.id}_${cellfieldName}`;
      formValues[key] = selectedRow[cellfieldName];
    });
    this.props.form?.setFieldsValue(formValues);
    const isInsertRow =
      isCurrentInsertRow === true ? isCurrentInsertRow : this.props.insertRowActive && this.isInsertRow(selectedRow);

    if (
      ((isBoolean &&
        (window.editingColumnData?.columnIndex?.toString() === activeElement.getAttribute("columnIndex")?.toString() ||
          window.editingColumnData === undefined)) ||
        (isDate && currentColumnValue !== selectedColumnValue)) &&
      !isInsertRow
    ) {
      this.handleOnRowEditFinished();
    }
  };

  handleCellBlur = (_e, rowIndex, columnIndex) => {
    const isInsertRow = window.editingColumnData?.isInsertRow;
    if (!this.props.editableAlwaysActive || isInsertRow) {
      return;
    }
    const currentColumnDataValue = _e.currentTarget.getAttribute("datavalue");
    const currentColumnDataType = _e.currentTarget.getAttribute("datatype");
    const rowData = window.editingColumnData?.rowData;
    const selectedRow = rowData;
    const selectedColumnValue = selectedRow[currentColumnDataValue]?.toString();
    let currentColumnValue = _e.target.value?.toString();
    let formattedDate = "";
    if (currentColumnValue) {
      currentColumnValue = currentColumnValue.replace(/[,]/g, "");
    }
    if (currentColumnValue && currentColumnDataType === ValueType.Date) {
      currentColumnValue = moment(currentColumnValue, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");
      formattedDate = moment(selectedColumnValue).format("YYYY-MM-DD HH:mm:ss");
    }

    if (currentColumnValue !== selectedColumnValue && currentColumnValue && currentColumnValue !== "") {
      console.log("cellblur", currentColumnValue, selectedColumnValue, window.editingColumnData);
      if (!isInsertRow) {
        if (formattedDate && formattedDate !== "") {
          if (selectedColumnValue !== formattedDate && formattedDate !== "Invalid date") {
            this.handleOnRowEditFinished(true);
          }
        } else {
          this.handleOnRowEditFinished(true);
        }
      }
      const selectedColumnIndexHiddenInput = this.getSelectedColumnIndexHiddenInput();
      if (selectedColumnIndexHiddenInput && columnIndex !== undefined) {
        selectedColumnIndexHiddenInput.value = columnIndex.toString();
      }
    }
  };

  getRowComponent = () => {
    const childList = React.Children.toArray(this.props.children);
    return childList[1];
  };

  getPagination = () => {
    if (this.props.pagination && this.props.pagination === true) {
      const paginationProps = {
        pageSize: this.state.pageSize,
        showSizeChanger: this.props.showSizeChanger,
        pageSizeOptions: this.getPageSizeOptions(),
        onShowSizeChange: (current: number, size: number) => this.onPageSizeChange(current, size)
      };
      return paginationProps;
    }
    return false;
  };

  onPageSizeChange = (current: number, size: number) => {
    this.setState({ pageSize: size });
  };

  getPageSizeOptions = () => {
    const options = this.props.options;
    if (options && options.length > 0) {
      const mappedOptions: number[] = [];
      options.forEach((option: any) => {
        const value = option.text?.trim() ?? option[this.props.datavaluefield];
        if (!isNaN(value) && value !== null && value !== undefined && value !== "") {
          mappedOptions.push(parseInt(value));
        }
      });
      mappedOptions?.sort((a, b) => a - b);
      if (mappedOptions.length > 0) return mappedOptions;
    }

    return BaseTable.DEFAULT_PAGE_SIZE_OPTIONS;
  };

  shouldComponentUpdate = (nextProps: any, nextState: any) => {
    if (nextProps.mode === "carousel") {
      if (this.props.dataSource === undefined || this.props.dataSource !== nextProps.dataSource) return true;
      return false;
    }
    if (nextProps.mode === "google-maps") {
      if (this.props.dataSource === undefined || this.props.dataSource !== nextProps.dataSource) {
        if (_.isEqual(this.props.dataSource, nextProps.dataSource) === false) {
          return true;
        }
      }
      if (this.refreshGoogleMapsBodyWrapper && this.state.value !== nextState.value) {
        const { value } = nextState;
        if (value != undefined && value.latitude != undefined && value.longitude != undefined) {
          if (this.props.autoCenter !== true) {
            this.refreshGoogleMapsBodyWrapper({
              value: nextState.value,
              latitude: value.latitude,
              longitude: value.longitude
            });
          } else {
            this.refreshGoogleMapsBodyWrapper({ value: nextState.value });
          }
        }

        this.refreshGoogleMapsBodyWrapper({ value: nextState.value });
      }
      return false;
    }
    return !shallowequal(nextProps, this.props) || !shallowequal(nextState, this.state);
  };

  getRowProps = () => {
    const result: any = {};
    if (this.props.mode === "gallery") {
      result.desktopColumnsCount = (this.props as GalleryItemProps).desktopColumnsCount;
      result.tabletColumnsCount = (this.props as GalleryItemProps).tabletColumnsCount;
      result.phoneColumnsCount = (this.props as GalleryItemProps).phoneColumnsCount;
    } else if (this.props.mode === "google-maps") {
      result.value = this.state.value;
      result.map = this.state.map;
      result.maps = this.state.maps;
      result.isDataMarkerSelected = this.state.isDataMarkerSelected;
    }
    return result;
  };

  convertToCoordsFieldsFromLatField = (record: any): number | undefined => {
    if (this.props.dataLatField === undefined) return undefined;
    if (this.props.dataLatField !== undefined && record[toCamelCase(this.props.dataLatField)] !== undefined) {
      const latitude = parseFloat(record[toCamelCase(this.props.dataLatField)]);
      return latitude;
    }
  };

  convertToCoordsFieldsFromLngField = (record: any): number | undefined => {
    if (this.props.dataLngField === undefined) return undefined;
    if (this.props.dataLngField !== undefined && record[toCamelCase(this.props.dataLngField)] !== undefined) {
      const longitude = parseFloat(record[toCamelCase(this.props.dataLngField)]);
      return longitude;
    }
  };

  getRowLat = (rowData: any) => {
    const latitude = this.convertToCoordsFieldsFromLatField(rowData);
    return latitude;
  };

  getRowLng = (rowData: any) => {
    const longitude = this.convertToCoordsFieldsFromLngField(rowData);
    return longitude;
  };

  resetEditableRow = () => {
    if (this.state.activeEditableRowIndex != -1 && this.props.onRowEditFinished) {
      this.handleOnRowEditFinished();
    }
  };

  getDataSource = () => {
    const isDesignTime = window.kuika?.isDesignTime;
    if (this.props.insertRowActive && this.props.dataSource && !isDesignTime) {
      let mockDataSource = { id: this.state?.randomGuid };
      let children = this.props.children[1].props.children;
      if (children?.length > 0) {
        children.forEach((element) => {
          if (
            element &&
            element.props &&
            element.props.children &&
            element.props.children[0] &&
            element.props.children[0].props &&
            element.props.children[0].props.value
          ) {
            var key = element.props.children[0].props.value as string;
            if (key) {
              key = key.replace("[datafield:", "").replace("]", "");
              if (key !== "id") {
                mockDataSource[key] = undefined;
              }
            }
          }
        });
      }
      if (this.props.insertRowPosition === "bottom") {
        let dataSource = this.props.dataSource;
        let newDataSource = [];
        if (this.props.pagination === true) {
          dataSource.forEach((element, index) => {
            newDataSource.push(element);
            if (index % 8 === 0 && index !== 0) {
              newDataSource.push(mockDataSource);
            } else if (index === dataSource.length - 1) {
              newDataSource.push(mockDataSource);
            }
          });
        } else {
          newDataSource = [...dataSource, mockDataSource];
        }
        return newDataSource;
      }
      return [mockDataSource, ...this.props.dataSource];
    }
    return this.props.dataSource;
  };

  getTableStyles = () => {
    if (this.props.mode === "carousel") {
      const { width, height, maxWidth, maxHeight } = this.props.style;
      delete this.props.style?.width;
      delete this.props.style?.height;
      delete this.props.style?.maxWidth;
      delete this.props.style?.maxHeight;
      return {
        width,
        height,
        maxWidth,
        maxHeight
      };
    }
  };

  setStartingIndex = (index: number) => {
    if (this.props.mode === "carousel") {
      this.setState({ carouselStartingIndex: index } as any);
      this.forceUpdate();
    }
  };

  turkishCharsToEnglish = (str) => {
    if (!str || typeof str !== "string") return str;

    str = str.replace(/i̇/g, "i");
    str = str.replace(/ı/g, "i");
    str = str.replace(/ö/g, "o");
    str = str.replace(/ü/g, "u");
    str = str.replace(/ğ/g, "g");
    str = str.replace(/ş/g, "s");
    str = str.replace(/ç/g, "c");
    str = str.replace(/İ/g, "I");
    str = str.replace(/Ö/g, "O");
    str = str.replace(/Ü/g, "U");
    str = str.replace(/Ğ/g, "G");
    str = str.replace(/Ş/g, "S");
    str = str.replace(/Ç/g, "C");

    return str;
  };

  setSearchText = (searchText: string) => {
    this.setState({ searchText } as any);
  };

  setColumnSearchTexts = (key: string, value: string) => {
    const columnSearchTexts: { [key: string]: string } = { ...this.state.columnSearchTexts };
    columnSearchTexts[key] = value;
    this.setState({ columnSearchTexts } as any);
  };

  onHeaderSearchBlur = () => {
    if (this.props.onConfigChange) this.props.onConfigChange();
  };

  filterListBySearchText(rows: any, searchText: any) {
    const searchLowerCase = this.turkishCharsToEnglish(searchText?.toString()?.toLowerCase());
    const searchUpperCase = this.turkishCharsToEnglish(searchText?.toString()?.toUpperCase());
    let filteredList = rows;
    if (searchText && searchText !== "") {
      filteredList = rows.filter((item: any) => {
        for (let key in item) {
          const itemLowerCase = this.turkishCharsToEnglish(item[key]?.toString()?.toLowerCase());
          const itemUpperCase = this.turkishCharsToEnglish(item[key]?.toString()?.toUpperCase());
          if (
            (typeof item[key] === "string" || typeof item[key] === "number") &&
            (itemLowerCase?.includes(searchLowerCase) || itemLowerCase?.includes(searchLowerCase))
          ) {
            return true;
          }
          if (
            (typeof item[key] === "string" || typeof item[key] === "number") &&
            (itemUpperCase?.includes(searchUpperCase) || itemUpperCase?.includes(searchUpperCase))
          ) {
            return true;
          }
        }
        return false;
      });
    }

    const columnSearchTexts = this.state.columnSearchTexts;
    if (columnSearchTexts) {
      for (let key in columnSearchTexts) {
        const columnSearchText: any = columnSearchTexts[key];
        if (columnSearchText || columnSearchText === false) {
          const filteredListByColumnSearchText = filteredList.filter((item: any) => {
            if (typeof item[key] === "string" || typeof item[key] === "number") {
              const itemLowerCase = this.turkishCharsToEnglish(item[key].toString().toLowerCase());
              const columnSearchTextLowerCase = this.turkishCharsToEnglish(columnSearchText.toLowerCase());
              const itemUpperCase = this.turkishCharsToEnglish(item[key].toString().toUpperCase());
              const columnSearchTextUpperCase = this.turkishCharsToEnglish(columnSearchText.toUpperCase());
              if (itemLowerCase.includes(columnSearchTextLowerCase)) {
                return true;
              }
              if (itemUpperCase.includes(columnSearchTextUpperCase)) {
                return true;
              }
            }
            if (typeof item[key] === "boolean") {
              if (item[key] === true && (columnSearchText === "true" || columnSearchText === true)) {
                return true;
              }
              if (item[key] === false && (columnSearchText === "false" || columnSearchText === false)) {
                return true;
              }
            }
            return false;
          });
          filteredList = filteredListByColumnSearchText;
        }
      }
    }

    return filteredList;
  }

  handleCheckboxChange = (checkedValues) => {
    const { columns } = this.state;

    const allVisibleColumns = columns.filter((c) => c.visibility !== "hidden").map((c) => c.id);
    const newHiddenColumnList: any = allVisibleColumns.filter((id) => !checkedValues.includes(id));

    this.setState({ hiddenColumnList: newHiddenColumnList });
  };

  handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const columns: any = this.reorder(this.state.columns, result.source.index, result.destination.index);

    const columnOrder = columns.map((col: any) => col.id);
    this.setState(
      {
        columns,
        columnOrder
      },
      () => {
        this.handleColumnOrderChange(columnOrder);
      }
    );
  };

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  setResizingColumnIndex = (index: any) => {
    (this.resizingColumnIndexRef as any).current = index;
    this.setState({ resizingColumnIndex: index });
  };

  resizeListener = (e: any) => {
    let newColumnWidth;
    if (this.resizingColumnIndexRef.current !== undefined) {
      const table = document.getElementById(this.props.id);
      if (table) {
        const column: any = table.querySelector(`[columnindex="${this.resizingColumnIndexRef.current}"]`);
        if (column) {
          const columnWidth = column.offsetWidth;
          newColumnWidth = columnWidth + e.movementX;
          if (newColumnWidth > 50) {
            column.style.width = `${newColumnWidth}px`;
            this.setState({
              columnWidths: { ...this.state.columnWidths, [this.resizingColumnIndexRef.current]: newColumnWidth }
            });
          }
        }
      }
    }
    window.onmouseup = () => {
      window.removeEventListener("mousemove", this.resizeListener);
      this.setState({ resizingColumnIndex: undefined });
      if (this.props.onConfigChange) this.props.onConfigChange();
    };
  };

  handleColumnOrderChange = (newOrder) => {
    this.setState({ columnOrder: newOrder });
  };

  handleTableChange = (pagination, filters, sorter) => {
    if (sorter) {
      const sorterOptions = {
        field: sorter.column?.dataValue,
        order: sorter.order,
        columnId: sorter.column?.id
      };
      this.setState({ sorterOptions });
    } else {
      this.setState({ sorterOptions: {} });
    }
    if (filters) {
      Object.values(filters).forEach((filter: any) => {
        if (filter && filter[0] && filter[0].value) {
          const columns = this.state.columns;
          const column: any = columns.find((c) => c.id === filter[0].columnId);
          if (column) {
            filter[0].dataValue = column.dataValue;
            filter[0].columnId = column.id;
          }
        }
      });
      this.setState({ filteringOptions: filters });
    } else {
      this.setState({ filteringOptions: {} });
    }
    if (this.props.onConfigChange) this.props.onConfigChange();
  };

  getFormattedValue(value: any, formatter) {
    if (formatter && value && value?.toString()?.trim() !== "") {
      switch (formatter) {
        case "money":
          if (Number.isNaN(Number(value))) {
            return "0.00";
          }

          return new Intl.NumberFormat("tr-TR", {
            style: "currency",
            currency: "TRY",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
            .format(Number(value))
            .replace("₺", "");

        case "money-var-1":
          if (Number.isNaN(Number(value))) {
            return "0.00";
          }

          return new Intl.NumberFormat("tr-TR", {
            style: "currency",
            currency: "TRY",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          })
            .format(Number(value))
            .replace("₺", "");

        case "money-tl":
          if (Number.isNaN(Number(value))) {
            return "₺0.00";
          }

          return new Intl.NumberFormat("tr-TR", {
            style: "currency",
            currency: "TRY",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }).format(Number(value));

        case "phone":
          return VMasker.toPattern(value, "(999) 999-9999");

        case "percent":
          return `${value}%`;

        case "fractional-2":
          const number2 = Number(value);
          return isNaN(number2) ? value : number2.toFixed(2);

        case "fractional-5":
          const number5 = Number(value);
          return isNaN(number5) ? value : number5.toFixed(5);

        default:
          return value;
      }
    }
    return value?.toString();
  }

  getFooterCellLabel = (footerOptions) => {
    const language = localStorage.getItem("ml");
    let firstCellLabel = "";
    if (footerOptions === FooterOptions.Sum && language === "tr_TR") {
      firstCellLabel = "Toplam";
    } else if (footerOptions === FooterOptions.Average && language === "tr_TR") {
      firstCellLabel = "Ortalama";
    } else if (footerOptions === FooterOptions.Count && language === "tr_TR") {
      firstCellLabel = "Adet";
    } else if (footerOptions === FooterOptions.Min && language === "tr_TR") {
      firstCellLabel = "Min";
    } else if (footerOptions === FooterOptions.Max && language === "tr_TR") {
      firstCellLabel = "Maks";
    } else if (footerOptions === FooterOptions.Sum) {
      firstCellLabel = "Total";
    } else if (footerOptions === FooterOptions.Average) {
      firstCellLabel = "Average";
    } else if (footerOptions === FooterOptions.Count) {
      firstCellLabel = "Count";
    } else if (footerOptions === FooterOptions.Min) {
      firstCellLabel = "Min";
    } else if (footerOptions === FooterOptions.Max) {
      firstCellLabel = "Max";
    } else {
      firstCellLabel = "";
    }
    return firstCellLabel;
  };

  renderSummary = () => {
    const isDesignTime = window.kuika?.isDesignTime;

    const hasFooter = this.state.columns?.some(
      (column: any) => column.footerOptions !== FooterOptions.None && column.footerOptions
    );
    if (!hasFooter) return <></>;

    const calculateTotals = (column) => {
      const { footerOptions, dataType, dataValue, footerFormatter } = column;
      if (
        footerOptions === FooterOptions.None ||
        (dataType !== ValueType.Number && footerOptions !== FooterOptions.Count)
      )
        return "";

      const filteredList =
        this.props.searchable || this.props.globalSearch
          ? this.filterListBySearchText(this.getDataSource(), this.state.searchText)
          : this.getDataSource();
      const columnData = filteredList?.map((row) => row[dataValue]) || [];

      switch (footerOptions) {
        case FooterOptions.Sum:
          return this.getFormattedValue(
            columnData.reduce((acc, val) => acc + (val || 0), 0),
            footerFormatter
          );
        case FooterOptions.Average:
          return this.getFormattedValue(
            columnData.length
              ? (columnData.reduce((acc, val) => acc + (val || 0), 0) / columnData.length).toFixed(2)
              : 0,
            footerFormatter
          );
        case FooterOptions.Count:
          return this.getFormattedValue(columnData.length, footerFormatter);
        case FooterOptions.Min:
          return this.getFormattedValue(Math.min(...columnData), footerFormatter);
        case FooterOptions.Max:
          return this.getFormattedValue(Math.max(...columnData), footerFormatter);
        default:
          return "";
      }
    };

    const totals = this.state.columns?.map(calculateTotals);

    const renderSummaryCell = (column, index) => {
      const { footerOptions, footerFormatter, dataType, style } = column;
      const isNumeric = dataType === ValueType.Number || isDesignTime || footerOptions === FooterOptions.Count;

      if (!isNumeric) return <Table.Summary.Cell key={index} index={index} />;
      const designTimeValue = footerOptions !== FooterOptions.None ? this.getFormattedValue("20", footerFormatter) : "";
      const content = (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>{this.getFooterCellLabel(footerOptions)}</span>
          <span
            style={{
              marginLeft:
                style?.textAlign === "-webkit-left" ? "8px" : style?.textAlign === "-webkit-right" ? "auto" : "8px"
            }}
          >
            {isDesignTime ? designTimeValue : totals[index] || ""}
          </span>
        </div>
      );

      return (
        <Table.Summary.Cell
          key={index}
          index={index}
          align={
            style?.textAlign === "-webkit-center"
              ? "center"
              : style?.textAlign === "-webkit-left"
              ? "left"
              : style?.textAlign === "-webkit-right"
              ? "right"
              : "left"
          }
        >
          {content}
        </Table.Summary.Cell>
      );
    };

    return (
      <Table.Summary>
        <Table.Summary.Row>{this.state.columns?.map(renderSummaryCell)}</Table.Summary.Row>
      </Table.Summary>
    );
  };

  onExport = () => {
    const file = new xlsx.File();
    const sheet = file.addSheet("Sheet1");

    const headerRow = sheet.addRow();
    this.state.columns.forEach(({ title }: any) => {
      const cell = headerRow.addCell();
      cell.value = title;
    });

    let filteredList =
      this.props.searchable || this.props.globalSearch
        ? this.filterListBySearchText(this.getDataSource(), this.state.searchText)
        : this.getDataSource();

    if (this.state.filteringOptions) {
      Object.entries(this.state.filteringOptions).forEach(([c, filters]: any) => {
        if (filters && filters.length > 0) {
          const { value, type, columnId } = filters[0];
          const column = this.state.columns.find((col) => col.id === columnId);
          if (column) {
            const { dataValue, dataType }: any = column;
            filteredList = filteredList.filter((record) =>
              BaseTable.applyFilter({ value, type }, record, dataValue, dataType)
            );
          }
        }
      });
    }

    if (this.state.sorterOptions && this.state.sorterOptions.field) {
      const { field, order } = this.state.sorterOptions;
      filteredList.sort((a, b) => {
        const result = BaseTable.compare(field, a, b);
        return order === "ascend" ? result : -result;
      });
    }

    filteredList.forEach((record) => {
      const row = sheet.addRow();
      this.state.columns.forEach(({ dataValue }: any) => {
        const cell = row.addCell();
        cell.value = objectPath.get(record, dataValue) ?? "";
      });
    });

    file
      .saveAs("blob")
      .then((blob) => {
        saveAs(blob, "export.xlsx");
      })
      .catch((error) => {
        console.error("Error saving the file:", error);
      });
  };

  render = (): ReactNode => {
    const isDesignTime = window.kuika?.isDesignTime;
    const defaultEmptyProps = {
      xl: 24 / (this.props as GalleryItemProps).desktopColumnsCount,
      md: 24 / (this.props as GalleryItemProps).tabletColumnsCount,
      sm: 24 / (this.props as GalleryItemProps).phoneColumnsCount,
      xs: 24 / (this.props as GalleryItemProps).phoneColumnsCount,

      style: {
        padding: "5px 5px"
      }
    };
    let defaultTemp = [];

    if (this.props.kuikacomponentname == "GalleryView" && !this.props.children) {
      let a = Array(24).fill(1);
      a.forEach(() => {
        defaultTemp.push(
          <Col {...defaultEmptyProps}>
            <div className="kuika_emptygalleryviewPlaceholder" />
          </Col>
        );
      });

      return <Row>{defaultTemp}</Row>;
    }
    if (this.props.mode == "carousel" && this.props.kuikacomponentname === "Carousel" && !this.props.children) {
      return <img src={CarouselPlaceholder} style={{ width: "100%" }} />;
    }
    if (this.props.mode == "carousel" && this.props.kuikacomponentname === "HorizontalGrid" && !this.props.children) {
      return <img src={HorizontalGridPlaceholder} style={{ width: "100%" }} />;
    }
    if (this.props.mode == "flex-grid" && !this.props.children && window.kuika.isDesignTime) {
      return <img src={FlexGridPlaceholder} style={{ width: "100%" }} />;
    }
    if (
      this.props.hideTableOnEmpty == true &&
      (this.props.dataSource == undefined || this.props.dataSource.length < 1)
    ) {
      return <></>;
    }

    const filteredList =
      this.props.searchable || this.props.globalSearch
        ? this.filterListBySearchText(this.getDataSource(), this.state.searchText)
        : this.getDataSource();
    const language = localStorage.getItem("ml");

    let noDataFoundMessageText = "No data found";
    if (language === "tr_TR") {
      noDataFoundMessageText = "Veri bulunamadı";
    } else if (language === "fr_FR") {
      noDataFoundMessageText = "Aucune donnée trouvée";
    } else if (language === "de_DE") {
      noDataFoundMessageText = "Keine Daten gefunden";
    } else if (language === "es_ES") {
      noDataFoundMessageText = "No se encontraron datos";
    }

    return (
      <>
        <div className={`kuika_table__wrapper${this.getClassName()}`}>
          {(this.props.globalSearch || this.props.columnChooser || this.props.export) && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginBottom: 12
              }}
            >
              {this.props.globalSearch && (
                <Input
                  placeholder={language === "tr_TR" ? "Ara..." : "Search..."}
                  style={{
                    width: 240
                  }}
                  value={this.state.searchText}
                  onChange={(e) => this.setSearchText(e.target.value)}
                  onBlur={() => {
                    if (this.props.onConfigChange) this.props.onConfigChange();
                  }}
                  readOnly={isDesignTime}
                />
              )}

              {this.props.export && (
                <div style={{ marginLeft: 12 }}>
                  <Button
                    type="primary"
                    icon={<DownloadOutlined />}
                    onClick={() => {
                      this.onExport();
                    }}
                  >
                    {language === "tr_TR" ? "Dışa Aktar" : "Export"}
                  </Button>
                </div>
              )}

              {this.props.columnChooser && (
                <div style={{ marginLeft: 12 }}>
                  <Popover
                    content={
                      <DragDropContext onDragEnd={this.handleDragEnd}>
                        <Droppable droppableId="columnChooser">
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              className="kuika_table__column_chooser__checkbox_group"
                            >
                              {this.state.columns
                                ?.filter((c) => c.visibility !== "hidden")
                                .map((c: any, index) => (
                                  <Draggable key={c.id} draggableId={c.id} index={index}>
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className="draggable-item"
                                      >
                                        <MenuOutlined className="drag-handle" />
                                        <Checkbox
                                          checked={!this.state.hiddenColumnList.includes(c.id)}
                                          onChange={(e) => {
                                            if (
                                              this.state.hiddenColumnList.length ===
                                                this.state.columns.filter((c) => c.visibility !== "hidden").length -
                                                  1 &&
                                              !e.target.checked
                                            ) {
                                              return;
                                            }
                                            const hiddenColumnList: any = e.target.checked
                                              ? this.state.hiddenColumnList.filter((id) => id !== c.id)
                                              : [...this.state.hiddenColumnList, c.id];
                                            this.setState({ hiddenColumnList }, () => {
                                              if (this.props.onConfigChange) this.props.onConfigChange();
                                            });
                                          }}
                                        >
                                          {c.title}
                                        </Checkbox>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    }
                    title="Column Chooser"
                    trigger="click"
                    placement="bottomLeft"
                  >
                    <UnorderedListOutlined
                      style={{
                        pointerEvents: isDesignTime ? "none" : "auto"
                      }}
                    />
                  </Popover>
                </div>
              )}
            </div>
          )}
          <Table
            id={this.props.id}
            style={{ ...this.getTableStyles() }}
            showHeader={this.props.showHeader}
            pagination={this.getPagination()}
            dataSource={filteredList}
            onChange={this.handleTableChange}
            components={{
              table: this.getViewWrapper(),
              header: {
                wrapper: this.state.headerRenderer,
                row: TableHeaderRow,
                cell: TableHeaderCell
              },
              body: {
                wrapper: this.getBodyWrapper(),
                row: this.state.rowRenderer,
                cell: this.getBodyCell()
              }
            }}
            summary={this.renderSummary}
            locale={{
              emptyText: (
                <Empty
                  image={
                    this.props.noDataFoundImage ? (
                      <Image value={this.props.noDataFoundImage} />
                    ) : this.props.mode === "table" || this.props.showNoDataFound ? (
                      <img
                        src={EmptyDataSvg}
                        style={{
                          width: "100%"
                        }}
                      />
                    ) : null
                  }
                  description={
                    this.props.mode === "table" || this.props.showNoDataFound
                      ? this.props.nodatafoundmessage || noDataFoundMessageText
                      : ""
                  }
                />
              )
            }}
            onRow={(rowValue: any, rowIndex?: number) => ({
              ...this.getRowProps(),
              onMouseLeave: (e: any) => {
                this.handleOnRowBlur(e, rowValue, rowIndex);
              },
              onDoubleClick: (e: any) => {
                this.handleRowDoubleClick(e, rowValue, rowIndex);
              },
              onTouchStart: (e: any) => {
                this.timeOutId = setTimeout(() => {
                  this.handleRowDoubleClick(e, rowValue, rowIndex);
                }, 500);
              },
              onTouchEnd: (e: any) => {
                clearTimeout(this.timeOutId);
              },
              onClickCapture: (e: any) => {
                this.handleRowClick(e, rowValue, rowIndex);
              },
              editMode: this.props.editableAlwaysActive ? true : this.state.activeEditableRowIndex == rowIndex,
              style: this.getRowStyle(rowValue, rowIndex),
              hoverFontColor: this.props.rowHoverFontColor,
              transformedOnMobileResolution: this.props.transformedOnMobileResolution,
              hoverBgColor: this.props.rowHoverBgColor,
              selectedBgColor: this.props.selectedRowColor,
              isSelected: this.state.selectedRowKeys.includes(rowValue.id),
              tableClassName: this.getClassName().trim(),
              showTableHeader: this.props.showHeader,
              desktopColumnsCount: (this.props as GalleryItemProps).desktopColumnsCount,
              tabletColumnsCount: (this.props as GalleryItemProps).tabletColumnsCount,
              phoneColumnsCount: (this.props as GalleryItemProps).phoneColumnsCount,
              mode: this.props.mode,
              rowData: rowValue,
              rowIndex,
              isInsertRow: this.isInsertRow(rowValue),
              handleAddRow: () => {
                if (this.props.onRowEditFinished) {
                  this.handleOnRowEditFinished();
                  window.editingColumnData = {};
                }
              },
              handleCancelEditing: () => {
                this.setState({ activeEditableRowIndex: -1 } as any);
              },
              onClick: () => this.resetEditableRow,
              rowComponent: this.getRowComponent(),
              lat: this.getRowLat(rowValue),
              lng: this.getRowLng(rowValue),
              dataLatField: this.props?.dataLatField,
              dataLngField: this.props?.dataLngField,
              marker: { lat: this.getRowLat(rowValue), lng: this.getRowLng(rowValue) },
              rowStyle: this.getRowStyle(rowValue, rowIndex),
              value: this.state.value,
              isDataMarkerSelected: this.state.isDataMarkerSelected,
              onClickDesign: (designClicked: boolean) => {
                if (this.props.onClickDesign) {
                  this.props.onClickDesign(designClicked);
                }
              },
              openDrawer: (content?: any) => {
                if (content !== undefined) {
                  if (this.refreshGoogleMapsBodyWrapper) {
                    this.refreshGoogleMapsBodyWrapper({ activeContent: content });
                  }
                }
              },
              designClicked: (this.props.designClicked as any) === "" ? true : this.props.designClicked,
              markerDetailView: this.props.markerDetailView,
              position: this.props.markerDetailPosition,
              height: this.props.markerDetailHeight,
              width: this.props.markerDetailWidth,
              selectedLocationIcon: this.props.selectedLocationIcon,
              selectedLocationColor: this.props.selectedLocationColor
            })}
            onHeaderRow={() => ({
              style: this.state.headerStyle,
              mode: this.props.mode,
              onClick: this.resetEditableRow
            })}
            columns={this.state.columns
              ?.filter((c) => c.visibility !== "hidden")
              ?.filter((c) => {
                return !this.state.hiddenColumnList?.includes(c.id);
              })
              .map((column: any, columnIndex: number) => {
                const { columns } = this.state;
                column.sortOrder =
                  this.state.sorterOptions?.field === column.dataValue &&
                  this.state.sorterOptions?.columnId === column.id
                    ? this.state.sorterOptions?.order
                    : undefined;
                if (this.props.tableWidthMode === "scroll" || this.props.tableWidthMode === "horizontal scroll") {
                  column.width = column.style.width ?? "min-content";
                  column.style.width = column.width;
                  column.style.minWidth = column.style.width;
                }
                if (this.state.columnWidths && this.state.columnWidths[columnIndex] !== undefined) {
                  column.width = this.state.columnWidths[columnIndex];
                  column.style.width = column.width;
                }
                const c: ColumnType<any> = {
                  ...column,
                  onCell: (rowData: any, rowIndex?: number): any => ({
                    onCellClick: (_e: any) => {
                      this.handleCellClick(_e, columnIndex, rowData, rowIndex);
                    },
                    onCellBlur: (_e: any) => {
                      this.handleCellBlur(_e, rowIndex, columnIndex);
                    },
                    style: column.style,
                    calculatedCellBgColor: column.calculatedCellBgColor,
                    calculatedCellFontColor: column.calculatedCellFontColor,
                    alwaysVisibleOnMobileResolution: column.alwaysVisibleOnMobileResolution,
                    rowIndex,
                    rowData,
                    columnIndex,
                    title: column.title,
                    columns: columns?.filter((x: any) => x.visible === true),
                    mode: this.props.mode,
                    isEditable: column.isEditableColumn,
                    isEditMode:
                      (column.isEditableColumn && this.state.activeEditableRowIndex == rowIndex) ||
                      (this.props.editableAlwaysActive && column.isEditableColumn),
                    editableAlwaysActive: this.props.editableAlwaysActive,
                    dataValue: column.dataValue,
                    dataType: column.dataType ?? ValueType.String,
                    setResizingColumnIndex: this.setResizingColumnIndex,
                    resizeListener: this.resizeListener,
                    index: columnIndex,
                    resizableColumns: this.props.resizableColumns
                  }),
                  onHeaderCell: (): any => ({
                    style: this.state.headerStyle,
                    columnStyle: column.style,
                    title: column.title,
                    mode: this.props.mode,
                    textDirection: this.props.children[0]?.props?.textDirection,
                    columnSearchText: this.state.columnSearchTexts,
                    setColumnSearchText: this.setColumnSearchTexts,
                    onHeaderSearchBlur: this.onHeaderSearchBlur,
                    searchable: this.props.searchable,
                    type: column.dataType ?? ValueType.String,
                    dataValue: column.dataValue,
                    index: columnIndex,
                    resizeListener: this.resizeListener,
                    setResizingColumnIndex: this.setResizingColumnIndex,
                    resizableColumns: this.props.resizableColumns,
                    hidden: this.state.hiddenColumnList.includes(column.id),
                    isSearchHidden: column.hideSearch,
                    isFilteringHidden: column.hideFiltering,
                    isSortingHidden: column.hideSorting
                  })
                };
                return c;
              })}
            loading={this.props.loading}
            size={this.props.size}
            bordered={false}
            scroll={
              this.props.tableWidthMode === "scroll" || this.props.tableWidthMode === "horizontal scroll"
                ? { x: "max-content" }
                : undefined
            }
            rowKey={"id"}
            rowSelection={
              this.props.multiSelect
                ? {
                    type: "checkbox",
                    selectedRowKeys: this.state.selectedRowKeys,
                    onChange: (selectedRowKeys: any, selectedRows: any, info) => {
                      if (info?.type === "all" || info?.type === "invert") {
                        return;
                      }
                      let _selectedRowsKeys: any = "";
                      selectedRowKeys.forEach((rowKey: any) => {
                        if (selectedRowKeys.indexOf(rowKey) === selectedRowKeys.length - 1) {
                          _selectedRowsKeys += rowKey;
                          return;
                        }
                        _selectedRowsKeys += `${rowKey},`;
                      });
                      this.setState({ selectedRowKeys, selectedRowKeysString: _selectedRowsKeys }, () => {
                        if (this.props.onSelectedValuesChange) {
                          this.props.onSelectedValuesChange();
                        }
                      });
                    },
                    onSelectAll: (selected, selectedRows, changeRows) => {
                      let _selectedRowsKeys: any = "";
                      selectedRows.forEach((row: any) => {
                        if (selectedRows.indexOf(row) === selectedRows.length - 1) {
                          _selectedRowsKeys += row.id;
                          return;
                        }
                        _selectedRowsKeys += `${row.id},`;
                      });
                      this.setState(
                        {
                          selectedRowKeys: selectedRows.map((row: any) => row.id),
                          selectedRowKeysString: _selectedRowsKeys
                        },
                        () => {
                          if (this.props.onSelectedValuesChange) {
                            this.props.onSelectedValuesChange();
                          }
                        }
                      );
                    }
                  }
                : undefined
            }
          />

          <input type="hidden" id={this.state.selectedIndexGuid} className="selected-index-guid"></input>
          <input type="hidden" id={this.state.selectedColumnIndexGuid} className="selected-column-index-guid"></input>
          <input type="hidden" id={this.state.selectedRowDataGuid} className="selected-row-data-guid"></input>
        </div>
      </>
    );
  };
}
